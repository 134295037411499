import React from 'react'
import moment from 'moment';
import configs from '../../../../configs/apiConfigs';
export const EmergencyDisplay = ({ handleModalClose, handleSuccess,appointment }: any) => {
    return (
        <>
            <div className="offcanvas-action d-flex">
                <button type="button" className="btn btn-link p-0 fw-normal fs-7 ms-auto" onClick={() => { handleModalClose() }}>Done</button>
            </div>

            <div className="offcanvas-header">
                <h5 className="offcanvas-title">Triage/Emergency</h5>
            </div>
            <div className="offcanvas-body">
                <div className="triage_form">
                    <div className="mb-3">
                        <label className="form-label">Clinic</label>
                        <select disabled className="form-control" name="clinicId" >
                            <option>{appointment && appointment.clinic && appointment.clinic.name ?appointment.clinic.name:''}</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Subject</label>
                        <input disabled type="text" className="form-control" id="" placeholder="Write the problem statement" name="subject" value={appointment && appointment.emergency && appointment.emergency.subject ?appointment.emergency.subject :''}  />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Description</label>
                        <textarea disabled className="form-control" id="" rows={3} placeholder="Write details about the problem" name="description" value={appointment && appointment.emergency && appointment.emergency.description ?appointment.emergency.description :''} ></textarea>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Appointment</label>
                        <select disabled className="form-control" name="appointment" >
                            <option>{appointment && appointment.doctor && appointment.doctor.fullname?appointment.doctor.fullname:'' } ({moment(appointment.date + " " + appointment.time, "YYYY-MM-DD hh:mm:a").format('MMM DD, YYYY - LT')})</option>
                        </select>
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Pictures</label>
                        <div className="d-flex mb-2">
                            {
                                (appointment && appointment.emergency && appointment.emergency.photos && appointment.emergency.photos.length > 0) &&
                                appointment.emergency.photos.map((image: any, index: number) => {
                                    return (
                                        <img key={Math.random() + index} src={configs.SOURCE_URL + image.name} className="border rounded me-2" alt="image" style={{ height: '50px', width: '50px' }} />
                                    )
                                })
                            }
                        </div>
                    </div>
                    {
                        (appointment && appointment.emergency && appointment.emergency.video)&&
                        <div className="mb-3">
                                <label className="form-label">Video</label>
                            <video width="350" height="150" controls >
                                <source src={configs.SOURCE_URL + appointment.emergency.video} type="video/mp4" />
                            </video>
                        </div>
                    }
                </div>
            </div>
            
        </>
    )
}