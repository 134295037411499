import React, { useState, useEffect } from 'react';
import { emailValidation } from '../../../../utils/validators';
import { Spinner } from 'react-bootstrap';
import { referInvitationSent } from '../../../services/refer';
import { toast } from 'react-toastify';
export const ReferByEmailSms = ({ handleModalClose, handleSuccess }: any) => {

    const [show, setShow] = useState(false);
    const [referList, setReferList]:any = useState([]);
    const [emailOrMobile, setEmailOrMobile]: any = useState('');
    const [emValidation, setEmValidation] = useState({ isInValid: false, msg: '' });
    const [isLoading, setIsLoading] = useState(false);

    const handleEmailOrMobile = () => {
        if (isNaN(emailOrMobile)) {
            if (!emailValidation(emailOrMobile)) {
                setEmValidation({ isInValid: true, msg: 'Invalid email' });
                return;
            }
        } else {
            if (emailOrMobile.length > 10 || emailOrMobile.length < 10) {
                setEmValidation({ isInValid: true, msg: 'Invalid mobile' });
                return;
            }
        }
        let alreadyAdded = referList.find((refer: any) => { return refer === emailOrMobile });
        if (alreadyAdded) {
            setEmValidation({ isInValid: true, msg: 'Already added.' });
            return;
        }
        setReferList([...referList, emailOrMobile]);
        setEmailOrMobile('');
    }

    const handleRemove = (removeEM:any) => {
        let findIndex = referList.findIndex((refer: any) => { return refer === removeEM });
        setReferList(referList.splice(findIndex, 1));
    }

    const onClickReferNow = () => {
        let request = {
            inviteOn: referList.toString()
        }
        setIsLoading(true);
        referInvitationSent(request).then((success) => {
            setIsLoading(false);
            if (success && success.status && success.status.error === false) {
                toast.success("Invitation sent successfully");
                setEmailOrMobile('');
            }
        }).catch((err) => {
            setIsLoading(false);
        });
    }

    return (
        <>
            <div className="offcanvas-action d-flex">
                <button type="button" className="btn btn-link p-0 fw-normal fs-7 ms-auto"
                    // data-bs-dismiss="offcanvas" aria-label="Close"
                    onClick={() => { handleModalClose() }}>
                    Done
                </button>
            </div>

            <div className="offcanvas-header">
                <h5 className="offcanvas-title">Refer a Friend</h5>
            </div>
            <div className="offcanvas-body">
                <div className="addedsmsemailbadge mb-3">
                    {
                        (referList && referList.length > 0) &&
                        referList.map((refer: any) => {
                            return (
                                <span className="badge bg-secondary me-2">{refer}
                                    <button type="button" className="btn text-white p-0 ms-2" onClick={()=>{handleRemove(refer)}}><i className="bi bi-dash-circle"></i></button>
                                </span>
                            )
                        })
                    }
                </div>
                <div className="adduseremailandsms">
                    <label className="form-label">Enter email address or 10 digit mobile numbers</label>
                    <div className="input-group mb-3">
                        <input type="text" className="form-control" value={emailOrMobile} placeholder="Email address or mobile number" onChange={(e)=>{setEmailOrMobile(e.target.value)}} />
                        <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={() => { handleEmailOrMobile() }}><i className="bi bi-plus-circle"></i></button>
                        {
                            (emValidation.isInValid) &&
                            <div className="form-error">{emValidation.msg}</div>
                        }
                    </div>
                    <div className="mb-3">
                        <button type="button" className="btn btn-dark w-100 fw-normal" onClick={()=>{onClickReferNow()}}>
                            Refer Now {
                                (isLoading) &&
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            }
                        </button>
                    </div>

                </div>
            </div>
        </>
    )
}
