import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { communicationUpdate, profileUpdate } from '../../../services/profile';
import { getCookie } from '../../../utils/cookies';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { showWaitingPresc, userLogout } from '../../../../store/auth/actions';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { Spinner } from 'react-bootstrap';
import { patientDataPending } from '../../../../store/patientData/actions';
export const SigninEmailEdit = ({ handleModalClose, handleSuccess, email,title,user }: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const userData = useSelector((state: any) => state.patientData.patientDataResponse, shallowEqual);
    const dispatch = useDispatch();
    const history = useHistory();
    const { register, setError, handleSubmit, setValue, getValues, errors, trigger, control, watch } = useForm({
        defaultValues: {
            email: '',
            cemail: '',
            globalError:''
        }
    });

    const handleUpdateEmail = (data:any) => {
        if (data.email === email) {
            setError('globalError', { type: 'custom', message: 'Old email and new email both are same.' });
            return;
        }
        if (data.email !== data.cemail) {
            setError('globalError', { type: 'custom', message: 'Email and confirm email not match.' });
            return;
        }
        const login: any = getCookie();
        setIsLoading(true);
        if (title === 'Sign in') {
            profileUpdate({
                type: 'email',
                email: data.email
            }, login.xpr_user_id).then((success) => {
                setIsLoading(false);
                if (success && success.status && success.status.error === false) {
                    toast.success("Email updated successfully");
                    handleSuccess();
                    dispatch(userLogout());
                    history.push('/login');
                }
            }).catch((err) => {
                setIsLoading(false);
                toast.success("Something went wrong.");
            });
        } else {
             communicationUpdate({
                type: 'email',
                email: data.email,
                dependantId:user.dependantId,
                guarantorId:user.guarantor
            }).then((success) => {
                setIsLoading(false);
                if (success && success.status && success.status.error === false) {
                    toast.success("Email updated successfully");
                    handleSuccess();
                    dispatch(patientDataPending({
                        uniqueId: userData.uniqueId
                    }));
                }
            }).catch((err) => {
                setIsLoading(false);
                toast.success("Something went wrong.");
            });
        }
            
    }
    
    return (
        <>
            <div className="offcanvas-action d-flex">
                <button type="button" className="btn btn-link p-0 fw-normal fs-7 ms-auto"
                    // data-bs-dismiss="offcanvas" aria-label="Close"
                    onClick={() => { handleModalClose() }}>
                    Done
                </button>
            </div>

            <div className="offcanvas-header">
                <h5 className="offcanvas-title">{title} email</h5>
            </div>

            <div className="offcanvas-body">
                <div className="current_data border-bottom pb-2 mb-2">
                    <div>Current {title} Email Address</div>
                    <span className="opacity-50 fs-7">{email ? email : ''}</span>
                </div>
                <div className="mb-3">
                    <label className="form-label">New {title === 'Sign in' ? 'sign in':'communication'} email address</label>
                    <input type="email" className="form-control" id="" placeholder={title === 'Enter new Sign in email address' ? 'sign in':' Enter new communication email address'} name="email" ref={register({
                        required: true,
                        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    })} />
                    {
                        (errors && errors.email && errors.email.type === 'required') &&
                        <div className="form-error">Email is required</div>
                    }
                    {
                        (errors && errors.email && errors.email.type === 'pattern') &&
                        <div className="form-error">Email is invalid</div>
                    }
                </div>
                <div className="mb-3">
                    <label className="form-label">Confirm new {title === 'Sign in' ? 'sign in':'communication'} email address</label>
                    <input type="email" className="form-control" id="" placeholder={title === 'Enter new Sign in email address' ? 'sign in':' Enter new communication email address'} name="cemail" ref={register({
                        required: true,
                        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    })} />
                    {
                        (errors && errors.cemail && errors.cemail.type === 'required') &&
                        <div className="form-error">Confirm email is required</div>
                    }
                    {
                        (errors && errors.cemail && errors.cemail.type === 'pattern') &&
                        <div className="form-error">Confirm email is invalid</div>
                    }
                    <input type="hidden" name="globalError" ref={register} />
                    {
                        (errors && errors.globalError && errors.globalError.type === 'custom') &&
                        <div className="text-danger error">{errors.globalError.message}</div>
                    }
                </div>
                {
                    (title === 'Sign in') &&
                    <div className="alert alert-danger text-center py-2">
                        Note: For security reasons you will be asked to sign in again
                    </div>
                }

                <div className="mb-3">
                    <button type="button" className="btn btn-dark w-100 fw-normal" onClick={handleSubmit(handleUpdateEmail)}>
                        {
                            (isLoading) ?
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                                :
                                'Save'
                        }
                    </button>
                </div>

            </div>
        </>
    )
}