import { Divider } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import { checkoutLoyalty, getBrandItems, getBrandList } from '../../../services/loyalty';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SharedOffCanvas } from '../../sharedOffCanvas/SharedOffCanvas';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { cloneDeep } from 'lodash';
import { patientDataPending } from '../../../../store/patientData/actions';
import { getCookie } from '../../../../utils';

export const RedeemPoints = ({ handleModalClose, handleSuccess }: any) => {
    const [rewardsPoints, setRewardPoints] = useState([]);
    const [selectedReward, setSelectedReward] = useState();
    const [showViewSummary, setShowViewSummary] = useState(false);
    const [cart, setCart] = useState([]);
    const [showCart, setShowCart] = useState(false);
    const patientData = useSelector((state: any) => state.patientData.patientDataResponse, shallowEqual);
    const totalSum = useMemo(() => {
        let sum = 0;
        patientData?.dependant?.forEach((item: any) => {
            if (item.points && Number.isFinite(Number(item.points))) {
                sum += Number(item.points);
            }
        });
        return sum;
    }, [patientData]);

    useEffect(() => {
        fetchBrandList();
    }, [patientData]);

    const fetchBrandList = async () => {
        const res = await getBrandList(patientData.uniqueId);
        console.log(res);
        setRewardPoints(res?.brands);
    }

    const slider = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
    };

    return (
        <>
            <div className="offcanvas-action d-flex">
                <button type="button" className="btn btn-link p-0 fw-normal fs-7 ms-auto" onClick={() => { handleModalClose() }}>
                    Done
                </button>
            </div>

            <div className="offcanvas-header">
                <h5 className="offcanvas-title">Points Accounts</h5>
            </div>

            <div className="offcanvas-body">
                <div className='d-flex justify-content-between mb-2'>
                    <div className='d-flex flex-column'>
                        <h6 className='mb-1'>{totalSum} Points</h6>
                    </div>
                    {
                        cart?.length > 0 &&
                        <div className='d-flex flex-column'>
                            <button type="button" className="btn btn-primary btn-custom mr-3" onClick={(e) => {  
                                setShowCart(true);
                            }}>View Cart</button>
                        </div>
                    }
                </div>
                <Divider />
                <div className='d-flex justify-content-center'>
                    <h4 className='mt-2 mb-2'>Reedem for gift cards with membership rewards points</h4>
                </div>
                <Divider />
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto auto auto auto',
                }}>
                {/* <Slider {...slider} className='mt-2 mb-2'> */}
                    {
                        (rewardsPoints && rewardsPoints.length > 0) &&
                        rewardsPoints.map((reward: any) => {
                            return (
                                <div key={reward.brand_id} style={{
                                    cursor: 'pointer'
                                }} className="d-flex flex-column pe-2" onClick={() => {
                                    setSelectedReward(reward);
                                    setShowViewSummary(true);
                                }}>
                                    <img src={reward?.imageUrls?.['300w-326ppi']} height={200} width={'100%'}></img>
                                    <div className='d-flex flex-column justify-content-center align-items-center'>
                                        <div className='fw-medium'>${reward?.min}-${reward.max}</div>
                                        <div className='fw-medium'>{reward?.min * 100}-{reward.max * 100} Points</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                {/* </Slider> */}
                </div>
                <Divider />
            </div>

            <SharedOffCanvas
                isOpen={showViewSummary}
                handleClose={() => { setShowViewSummary(false); }}
                Children={ViewRewardSummary}
                handleSuccess={() => {
                    setShowViewSummary(false);
                }}
                style={{
                    width: '90vw'
                }}
                reward={selectedReward}
                setCart={setCart}
                cart={cart}
            />

            <SharedOffCanvas
                isOpen={showCart}
                handleClose={() => { setShowCart(false); }}
                Children={ShowCart}
                style={{
                    width: '90vw'
                }}
                setCart={setCart}
                cart={cart}
                totalSum={totalSum}
                handleSuccess={() => {
                    setCart([]);
                    setShowCart(false);
                    setSelectedReward(undefined);
                    setShowViewSummary(false);
                }}
            />
        </>
    )
};

const ViewRewardSummary = ({
    handleModalClose,
    reward,
    setCart,
    cart
}: any) => {
    const patientData = useSelector((state: any) => state.patientData.patientDataResponse, shallowEqual);
    const [brandItems, setBrandItems] = useState([]);
    const [quantity, setQuantity] = useState('1');
    const [selectedBrandItem, setSelectedBrandItem] = useState('');
    const [show, setShow] = useState(false);
    const [text, setText] = useState('');
    useEffect(() => {
        fetchBrandList();
    }, [reward]);

    const fetchBrandList = async () => {
        const res = await getBrandItems(patientData.uniqueId, reward.brand_id);
        console.log(res);
        setBrandItems(res?.brandItems);
    }

    const handleAddToCart = () => {
        const brandItem: any = brandItems?.find((item: any) => `${item.faceValue}-${item.utid}` === selectedBrandItem);
        setCart([...cart, {
            reward: reward,
            quantity: quantity,
            faceValue: brandItem?.faceValue,
            utid: brandItem?.utid
        }]);
        handleModalClose();
        toast.success('Added to cart');
    }
    const handleClose = () => {
        setShow(false);
    }
    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                        <div dangerouslySetInnerHTML={{ __html: text }}></div>
                    </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className="offcanvas-action d-flex">
                <button type="button" className="btn btn-link p-0 fw-normal fs-7 ms-auto" onClick={() => { handleModalClose() }}>
                    Done
                </button>
            </div>

            <div className="offcanvas-header">
                <h5 className="offcanvas-title">Reward Summary</h5>
            </div>

            <div className="offcanvas-body">
                <div className='row'>
                    <div className='col d-flex flex-column justify-content-start'>
                        <img src={reward?.imageUrls?.['300w-326ppi']} height={200} width={300}></img>
                        <button className='btn btn-link p-0' onClick={() => {
                            setShow(true);
                            setText(reward?.description);
                        }}>View Description</button>
                        <button className='btn btn-link p-0' onClick={() => {
                            setShow(true);
                            setText(reward?.disclaimer);
                        }}>View Disclaimer</button>
                        {/* <div dangerouslySetInnerHTML={{ __html: reward?.description }}></div>
                        <div dangerouslySetInnerHTML={{ __html: reward?.disclaimer }}></div> */}
                    </div>
                    <div className='col'>
                        <div className="form-group col-md-6">
                            <label>Quantity</label>
                            <input type="number" min={1} className="form-control"
                                onChange={(e) => { setQuantity(e.target.value); }} value={quantity} />
                        </div>
                    </div>
                    <div className='col'>
                        <Form>
                            {
                                brandItems?.map((item: any, index: number) => {
                                    return (
                                        <Form.Check // prettier-ignore
                                            key={item?.brandItem_id}
                                            type={'radio'}
                                            id={`brandItem`}
                                            name='brandItem'
                                            value={`${item.faceValue}-${item.utid}`}
                                            checked={`${item.faceValue}-${item.utid}` === selectedBrandItem}
                                            onChange={(event) => {
                                                setSelectedBrandItem(event.target.value);
                                            }}
                                            label={(
                                                <div className='d-flex flex-column'>
                                                    <div>
                                                        ${item?.faceValue}
                                                    </div>
                                                    <div>
                                                        {item?.faceValue * 100} Points
                                                    </div>
                                                </div>
                                            )}
                                        />
                                    )
                                })
                            }
                        </Form>
                    </div>
                </div>  
                <div className='d-flex w-100 justify-content-end'>
                    <button className='btn btn-primary btn-custom mr-3' disabled={selectedBrandItem === ''} onClick={handleAddToCart}>Add to cart</button>
                </div>
            </div>
        </>
    )
}

const ShowCart = ({
    handleModalClose,
    cart,
    setCart,
    totalSum,
    handleSuccess
}: any) => {
    const dispatch = useDispatch();
    const patientData = useSelector((state: any) => state.patientData.patientDataResponse, shallowEqual);
    const dependant = useMemo(() => {
        return patientData?.dependant?.find((item: any) => item.loginId === patientData.id);
    }, [patientData]);
    const sum = useMemo(() => {
        let sum = 0;
        cart?.forEach((item: any) => {
            sum += (Number(item?.faceValue) * Number(item?.quantity)) * 100;
        });
        return sum;
    }, [cart]);

    const handleCheckout = async () => {
        const res = await checkoutLoyalty(patientData.uniqueId, {
            dependantId: dependant?.id,
            cart: cart?.map((item: any) => ({
                qty: item.quantity,
                faceValue: item.faceValue,
                utid: item.utid
            }))
        });
        if (res?.status?.error === false) {
            const login: any = getCookie();
            toast.success(res?.status?.msg ?? 'Succesfully purchased.');
            handleSuccess();
            dispatch(patientDataPending({ uniqueId: login.xpr_user_id, doNotShowLoader: true}));
        } else {
            toast.error(res?.status?.msg ?? 'Failed to purchase');
            handleSuccess();
        }
    }

    const handleRemoveItem = (item: any, index: number) => {
      const cloned = cloneDeep(cart);
      setCart(cloned.filter((item: any, _index: number) => index !== _index));
    }

    return (
        <>
            <div className="offcanvas-action d-flex">
                <button type="button" className="btn btn-link p-0 fw-normal fs-7 ms-auto" onClick={() => { handleModalClose() }}>
                    Done
                </button>
            </div>

            <div className="offcanvas-header">
                <h5 className="offcanvas-title">Order Summary</h5>
            </div>

            <div className="offcanvas-body">
               <Divider/>
               <div className='row mt-2 mb-2'>
                    <div className='col'>
                        Item({cart.length})
                    </div>
                    <div className='col'>
                        Points
                    </div>
               </div>
               <Divider/>
               {
                    cart?.map((item: any, index: number) => {
                        return (
                            <>
                                <div className='row mt-2 mb-2' key={index}>
                                    <div className='col d-flex flex-column'>
                                        <p className='fw-medium'>{item?.reward?.brandName}</p>
                                        <label>${item?.faceValue}</label>
                                        <label>X{item?.quantity}</label>
                                    </div>
                                    <div className='col flex-column'>
                                        <p>{(Number(item?.faceValue) * Number(item?.quantity)) * 100}</p>
                                        <button className='btn btn-link p-0' onClick={() => {
                                            handleRemoveItem(item, index);
                                        }}>Remove</button>
                                    </div>
                                </div>    
                                <Divider/>
                            </>
                        )
                    })
               }
               <div className='row mt-2'>
                    <div className='col d-flex flex-column'>
                        <h5>Points Total</h5>
                    </div>
                    <div className='col'>
                        <p>{sum}</p>
                    </div>
                </div>  
                <div className='row'>
                    <div className='col d-flex flex-column'>
                        <h6>Remaining Points</h6>
                    </div>
                    <div className='col'>
                        <p>{Number(totalSum) - Number(sum)}</p>
                    </div>
                </div>  
                {
                    ((Number(totalSum) - Number(sum)) < 0) &&
                    <Alert variant='error'>You do not have sufficient points</Alert>
                }
                <button disabled={cart.length === 0 || ((Number(totalSum) - Number(sum)) < 0)} type="button" className="btn btn-primary btn-custom mr-3" onClick={(e) => {  
                    handleCheckout();
                }}>Checkout</button>
            </div>
        </>
    )
}