/* export const Referral = () => {
    const [isShowReferFriend, setIsShowReferFriend] = useState(false);
    return (
        <>

            <div className="card_item staticad card p-3">
                <div className="infocard_header mb-2">
                    <div className="card_ititle">Give $50. Get $50. </div>
                    <div className="card_desc">
                        <label className="text-dark opacity-75">Coming soon...</label>
                    </div>
                </div>

                <div className="card_ilink"></div>

            </div>


            <SharedOffCanvas
                isOpen={isShowReferFriend}
                handleClose={() => { setIsShowReferFriend(false) }}
                Children={ReferFriend}
                handleSuccess={() => {
                    setIsShowReferFriend(false)
                }}
            />
        </>
    )
} */

import React, { useState } from "react";
import { SharedOffCanvas } from "../../sharedOffCanvas/SharedOffCanvas";
import { ReferFriend } from "../xcarepoints/ReferFriend";
import { useHistory } from "react-router-dom";

export const MyOffers = (props: any) => {
  const [isShowReferFriend, setIsShowReferFriend] = useState(false);
  const history = useHistory();

  return (
    <>
      <div className="card_item rew_loy_point card p-3">
        <div className="infocard_header mb-2">
          <div className="card_ititle">My Offers</div>
          <div className="card_desc opacity-75">
            {/* You don't have any Rewards or Loyalty points. */}
            {/* Coming soon... */}
          </div>
        </div>
        <div
          className="infocard-body"
          onClick={() => {
            setIsShowReferFriend(true);
          }}
        >
          <div className="av-txt">
            <button
              type="button"
              className="btn btn-link p-0"
              onClick={() => {
                setIsShowReferFriend(true);
              }}
            >
              <div className="av_title p-0"> Refer a friend</div>
            </button>
            <div className="av_subtitle">Give & Get Points</div>
          </div>
        </div>
        <div className="card_ilink fix_bottom">
          {/* <button
            type="button"
            className="btn btn-link p-0 fw-normal fs-7 me-3"
            onClick={() => {
              setIsShowReferFriend(true);
            }}
          >
            Refer a Friend
          </button> */}
          <button
            type="button"
            className="btn btn-link p-0 fw-normal fs-7"
            onClick={() => {
              history.push({ pathname: "/offers" });
            }}
          >
            View all offers
          </button>
        </div>
      </div>
      {/* 
            <div className="card_item rew_loy_point card p-3">
                <div className="infocard_header d-flex justify-content-between mb-3">
                    <div className="card_ititle">Your Xcare Points</div>
                    <div className="card_ilink">
                        <button type="button" className="btn btn-link p-0 fw-normal fs-7">Redeem Now</button>
                    </div>
                </div>
                <div className="infocard_body">
                    <div className="d-flex">
                        <div className="rewardsp me-3 pe-3 border-end">
                            <div className="fs-7">Rewards: 5000 pt.</div>
                            <div className="fs-4">$50.00</div>
                        </div>

                        <div className="loyalp">
                            <div className="fs-7">Loyalty: 4000 pt.</div>
                            <div className="fs-4">$40.00</div>
                        </div>
                    </div>
                </div>
            </div> */}

      {/* <ReferFriend isShow={isShowReferFriend} handleClose={() => { setIsShowReferFriend(false) }} /> */}
      <SharedOffCanvas
        isOpen={isShowReferFriend}
        handleClose={() => {
          setIsShowReferFriend(false);
        }}
        Children={ReferFriend}
        handleSuccess={() => {
          setIsShowReferFriend(false);
        }}
      />
    </>
  );
};
