import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { addExpense, fetchClinicsAndSpentOnList } from '../../../services/insuranceCalculator';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../../store/loadingIndicator/actions'
export const AddSpent = ({ handleModalClose, handleSuccess, dependant }: any) => {
    const dispatch = useDispatch();
    const [clinics, setClinics] = useState([]);
    const [spentOnList, setSpentOnList] = useState([]);
    const { register, setError, handleSubmit, setValue, getValues, errors, trigger, control, watch } = useForm({
        defaultValues: {
            clinicid: '',
            clinicname: '',
            spenton: '',
            amount: ''
        }
    });

    useEffect(() => {
        fetchClinicsAndSpentOnData();
    }, []);
    
    const fetchClinicsAndSpentOnData = () => {
        dispatch(fetchLoadingPending());
        fetchClinicsAndSpentOnList(dependant.id).then((success: any) => {
            dispatch(fetchLoadingSuccess(false));
            setClinics(success.clinics);
            setSpentOnList(success.spentOn);
            if (success.clinics && success.clinics.length > 0) {
                setValue('clinicid', success.clinics[0].id);
            }
            if (success.spentOn && success.spentOn.length > 0) {
                setValue('spenton', success.spentOn[0].id);
            }
        }).catch((err: any) => {
            dispatch(fetchLoadingSuccess(false));
            console.log(err);
        })
    }

    const onAdd = (data: any) => {
        if (getValues('clinicid') === 'other' && getValues('clinicname')=== '') {
            setError('clinicname', { type: 'required', message: 'Clinic Name is required' });
            return;
        }
        const request = {
            detailId: data.spenton,
            clinicId: data.clinicid,
            amount: data.amount,
            other:data.clinicname,
        }
        dispatch(fetchLoadingPending());
        addExpense(request,dependant.id).then((success: any) => {
            dispatch(fetchLoadingSuccess(false));
            console.log('successSpenton', success);
            toast.success('Expence add successfully.');
            handleSuccess();
        }).catch((err: any) => {
            dispatch(fetchLoadingSuccess(false));
            console.log(err);
        })
    }
    return (
        <>
            <div className="offcanvas-action d-flex">
                <button type="button" className="btn btn-link p-0 fw-normal fs-7 ms-auto"
                    onClick={() => { handleModalClose() }}>
                    Done
                </button>
            </div>
            <div className="offcanvas-header">
                <h5 className="offcanvas-title">Add new expense</h5>
            </div>
            <div className="offcanvas-body">
                <div className="add_covform">
                    <div className="mb-3">
                        <label className="form-label">Select clinic</label>
                        <select className="form-select" name="clinicid" ref={register}>
                            {
                                (clinics && clinics.length > 0) &&
                                clinics.map((clinic: any) => {
                                    return (
                                        <option key={clinic.id} value={clinic.id}>{clinic.name}</option>
                                    )
                                })
                            }
                            <option value={'other'}>Other</option>
                        </select>
                    </div>
                    <div className={watch('clinicid') === 'other' ? "mb-3" : 'd-none'}>
                        <label className="form-label">Clinic Name</label>
                        <input type="text" className="form-control" id="" name="clinicname" placeholder="Enter Clinic Name" ref={register} />
                        {
                            (errors && errors.clinicname && errors.clinicname.type === 'required') &&
                            <div className="form-error">Clinic Name is required</div>
                        }
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Spent on</label>
                        <select className="form-select" name="spenton" ref={register}>
                            {
                                (spentOnList && spentOnList.length > 0) &&
                                spentOnList.map((item: any) => {
                                    return (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Spent amount</label>
                        <input type="text" className="form-control" id="" placeholder="Enter amount" name="amount"
                            ref={register({
                                required: true,
                                pattern: /^[0-9]+$/
                            })} />
                        {
                            (errors && errors.amount && errors.amount.type === 'required') &&
                            <div className="form-error">Spent Amount is required</div>
                        }
                        {
                            (errors && errors.amount && errors.amount.type === 'pattern') &&
                            <div className="form-error">Spent Amount is Invalid</div>
                        }
                    </div>
                    <div className="mb-3">
                        <button type="button" className="btn btn-dark w-100 fw-normal"
                            disabled={!dependant}
                            onClick={handleSubmit(onAdd)}>
                            Add
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
