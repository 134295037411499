import React, { useEffect, useState } from 'react';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import apiConfigs from '../../../configs/apiConfigs';
import { Elements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { useDispatch } from 'react-redux';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../../store/loadingIndicator/actions';
import { addStripeCard } from '../../../services/card';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
const stripePromise = loadStripe(apiConfigs.STRIPE_PUBLIC_KEY);
let cardNumberElement: any = null;
export const PaymentAddCard = ({ handleModalClose, handleSuccess }: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [stripeReady, setStripeReady] = useState(false);
	const [cardHolderName, setCardHolderName] = useState('');
    const [cardValidation, setCardValidation] = useState({ invalid: false, msg: '' });
    const [isSaveDefault, setIsSaveDefault] = useState(false);
	const dispatch = useDispatch();
	const cardNumberRef = (element: any) => {
		if (element) {
			dispatch(fetchLoadingPending());
			cardNumberElement = element;
			dispatch(fetchLoadingSuccess(false));
			setStripeReady(true);
		}
	};
	const createStripeToken = () => {
        try {
            setIsLoading(true);
			//dispatch(fetchLoadingPending());
			stripePromise.then((success: Stripe | null) => {
				if (success) {
					const stripe = success;
					stripe.createToken(cardNumberElement, { name: cardHolderName }).then((success: any) => {
                        //dispatch(fetchLoadingSuccess(false));
                        setIsLoading(false);
						if (success.error && (success.error.code === "incomplete_number" || success.error.code === "invalid_number")) {
							setCardValidation({ invalid: true, msg: success.error.message });
						} else if (cardHolderName === "") {
							setCardValidation({ invalid: true, msg: 'Card Holder name required' });
						} else if (success.error && (success.error.code === "incomplete_expiry" ||
							success.error.code === "invalid_expiry_year_past" ||
							success.error.code === "invalid_expiry_year")) {
							setCardValidation({ invalid: true, msg: success.error.message });
						} else if (success.error && (success.error.code === "incomplete_cvc")) {
							setCardValidation({ invalid: true, msg: success.error.message });
						} else if (success.error) {
							setCardValidation({ invalid: true, msg: success.error.message });
						} else if (success.token) {
							setCardValidation({ invalid: false, msg: '' });
                            //dispatch(fetchLoadingPending());
                            setIsLoading(true);
							addStripeCard({ stripeToken: success.token.id,defaultStatus:isSaveDefault }).then((success) => {
                                //dispatch(fetchLoadingSuccess(false));
                                setIsLoading(false);
                                if (success.status && success.status.error === false) {
                                    toast.success('Card added successfully.')
                                    handleSuccess();
								}
							}).catch((err) => {
                                //dispatch(fetchLoadingSuccess(false));
                                setIsLoading(false);
                                toast.error('Something went wrong.')
							});
						}
					}).catch((err) => {
                        //dispatch(fetchLoadingSuccess(false));
                        setIsLoading(false);
					});
				}
			}, (error) => {
                //dispatch(fetchLoadingSuccess(false));
                setIsLoading(false);
			});
		} catch (err) {
            //dispatch(fetchLoadingSuccess(false));
            setIsLoading(false);
		}
	}
	const onFinish = () => {
		createStripeToken();
	}
    return (
        <>
            <div className="offcanvas-action d-flex">
                <button type="button" className="btn btn-link p-0 fw-normal fs-7 ms-auto"
                    // data-bs-dismiss="offcanvas" aria-label="Close"
                    onClick={() => { handleModalClose() }}>
                    Done
                </button>
            </div>

            <div className="offcanvas-header">
                <h5 className="offcanvas-title">Add Card</h5>
            </div>

            <div className="offcanvas-body">
                <Elements stripe={stripePromise}>
                <div className="mb-3">
                    <label className="form-label">Credit card number</label>
                    <CardNumberElement className={"form-control"}
										options={{ showIcon: true, placeholder: "1234 1234 1234 1234" }}
										onReady={cardNumberRef}></CardNumberElement>
                </div>
                <div className="mb-3">
                    <label className="form-label">Name on card</label>
                    <input type="text" className="form-control" placeholder="Name on card" value={cardHolderName} onChange={e => setCardHolderName(e.target.value)} />
                </div>

                <div className="row mb-3">
                    <div className="col-7">
                        <label className="form-label">Expiration date (mm/yy)</label>
                        <CardExpiryElement className="form-control"></CardExpiryElement>
                    </div>

                    <div className="col-5">
                        <label className="form-label">CVV</label>
                        <CardCvcElement options={{ placeholder: "CVV" }} className="form-control"></CardCvcElement>
                    </div>
                </div>
                </Elements>
                <div className="form-check mb-3">
                    <input className="form-check-input" type="checkbox" value="" id="saveFor" onChange={()=>{setIsSaveDefault(!isSaveDefault)}} />
                    <label className="form-check-label" htmlFor="saveFor">
                        Save this card for next payment.
                    </label>
                </div>
                {
                    cardValidation.invalid === true &&
                    <div className="form-error">
                        {cardValidation.msg}
                    </div>
                }

                <div className="mb-3">
                    <button type="button" className="btn btn-dark w-100 fw-normal" onClick={()=>{onFinish();}}>
                        {
                            (isLoading) ?
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                                :
                                'Save'
                        }
                    </button>
                </div>

            </div>
        </>
    )
}
