import React, { useState } from 'react';
import { Animated } from 'react-animated-css';
import { Spinner } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LABELS, TITLES_LABELS } from '../../constants';
import { verifyOtpForgotpassword } from '../../services/auth';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import { useEffect } from 'react';
export const ForgotPasswordOtp = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { register, setError, handleSubmit, setValue, getValues, errors, trigger } = useForm();
    const history = useHistory();
    const location: any = useLocation();
    const match: any = useRouteMatch();
    useEffect(() => {
        console.log(location)
        if (!(location && location.state)) {
            history.replace('/forgot-password');
        }
    }, []);
    const onOtpVerifyClick = (data: any) => {
        if (match.params.id) {
            setIsLoading(true);
            const obj = {
                patientotpId: match.params.id,
                modelType: "verifyOtp"
            };
            if (location && location.state.email) {
                Object.assign(obj, {
                    otpeverify: getValues('eotp')
                });
            }
            if (location && location.state.phone) {
                Object.assign(obj, {
                    otppverify: getValues('potp')
                });
            }
            verifyOtpForgotpassword(obj).then((success) => {
                setIsLoading(false);
                if (success && success.status && success.status.error === false) {
                    const queryParam: any = new URLSearchParams(location.search);
                    if (queryParam.get('redirect_url') && queryParam.get('redirect_url') !== null && queryParam.get('redirect_url') !== "") {
                        history.push('/reset-password/' + success.status.patientuniqueId + '?redirect_url=' + queryParam.get("redirect_url"));
                    } else {
                        history.push('/reset-password/' + success.status.patientuniqueId);
                    }
                } else {
                    toast.error(success.status.msg);
                }
            }).catch((err) => {
                setIsLoading(false);
                toast.error(err.msg);
            });
        } else {
            toast.error("Invalid request");
        }
    }
    return (
        <ErrorBoundary>
            <div className="master_patientp">
                {/*  {
                    isLoading &&
                    <LoadingIndicator></LoadingIndicator>
                } */}

                <section className="login-page">
                    <Helmet><title>{TITLES_LABELS.META.OTP}</title></Helmet>
                    <div className="container">
                        <div className="row flex-center min-vh-100 py-5">
                            <div className="col-sm-8 col-md-6 col-lg-6 col-xl-5 col-xxl-4 m-auto">
                                <Animated animationIn="fadeIn" animationOut="zoomOut" animationInDuration={1000} isVisible={true}>
                                    <div className="card shadow-sm ">
                                        <div className="card-header bg-light px-5 py-3">
                                            <img src={require('../../../assets/images/logo-black.svg')} alt="logo" />
                                        </div>
                                        <div className="card-body p-5">
                                            <div className={"mb-3 " + ((location && location?.state?.email) ? '' : 'd-none')}>
                                                <label className="form-label fw-medium">Please enter OTP received on email to confirm reset password.</label>
                                                <input type="text" disabled={isLoading} className="form-control" name="eotp" placeholder="1234" maxLength={4} ref={register({
                                                    required: (location && location?.state?.email) ? true : false,
                                                    minLength: 4, maxLength: 4,
                                                    pattern: /^[0-9]+$/
                                                })} />
                                                {
                                                    (errors && errors.eotp && errors.eotp.type === 'required') &&
                                                    <div className="text-danger error">OTP is required.</div>
                                                }
                                                {
                                                    (errors && errors.eotp && errors.eotp.type === 'pattern') &&
                                                    <div className="text-danger error">Only number allowed.</div>
                                                }
                                                {
                                                    (errors && errors.eotp && errors.eotp.type === 'minLength') &&
                                                    <div className="text-danger error">Min length is 4.</div>
                                                }
                                                {
                                                    (errors && errors.eotp && errors.eotp.type === 'maxLength') &&
                                                    <div className="text-danger error">Max length is 4.</div>
                                                }
                                                {
                                                    (errors && errors.eotp && errors.eotp.type === 'custom') &&
                                                    <div className="text-danger error">{errors.eotp.message}</div>
                                                }
                                            </div>
                                            <div className={"mb-3 " + ((location && location?.state?.phone) ? '' : 'd-none')}>
                                                <label className="form-label fw-medium">Please enter OTP received on mobile# to confirm reset password.</label>
                                                <input type="text" disabled={isLoading} className="form-control" name="potp" placeholder="1234" maxLength={4} ref={register({
                                                    required: (location && location?.state?.phone) ? true : false,
                                                    minLength: 4, maxLength: 4,
                                                    pattern: /^[0-9]+$/
                                                })} />
                                                {
                                                    (errors && errors.potp && errors.potp.type === 'required') &&
                                                    <div className="text-danger error">OTP is required.</div>
                                                }
                                                {
                                                    (errors && errors.potp && errors.potp.type === 'pattern') &&
                                                    <div className="text-danger error">Only number allowed.</div>
                                                }
                                                {
                                                    (errors && errors.potp && errors.potp.type === 'minLength') &&
                                                    <div className="text-danger error">Min length is 4.</div>
                                                }
                                                {
                                                    (errors && errors.potp && errors.potp.type === 'maxLength') &&
                                                    <div className="text-danger error">Max length is 4.</div>
                                                }
                                                {
                                                    (errors && errors.potp && errors.potp.type === 'custom') &&
                                                    <div className="text-danger error">{errors.potp.message}</div>
                                                }
                                            </div>
                                            <button type="button" className="btn btn-primary w-100" onClick={handleSubmit(onOtpVerifyClick)}>Verify
                                                <span className="aft_load right">
                                                    <>
                                                        {
                                                            isLoading === true &&
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        }
                                                    </>
                                                </span>
                                            </button>
                                        </div>

                                    </div>
                                </Animated>
                            </div>
                            <div className="text-center fw-normal footer-srip">Copyright © {new Date().getFullYear()} Medesphere Inc. All rights reserved.</div>
                        </div>
                    </div>
                </section>
            </div>
        </ErrorBoundary>
    )
}