import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { patientDataPending } from '../../../../store/patientData/actions';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { SharedOffCanvas } from '../../sharedOffCanvas/SharedOffCanvas';
import { AddEditMemberForm } from './AddEditMemberForm';
import { joinLoyalty } from '../../../services/loyalty';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
export const MyFamilyList = ({ isShow, handleClose }: any) => {
    const userData = useSelector((state: any) => state.patientData.patientDataResponse, shallowEqual);
    const [familyMembers, setFamilyMembers] = useState([]);
    const [selectedMember, setSelectedMember] = useState('');
    const [isAddEditMember, setIsAddEditMember] = useState(false);
    const [show, setShow] = useState(isShow);
    const [title, setTitle] = useState('Add');
    const [joinLoyaltyLoader, setJoinLoyaltyLoader] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        if (userData && userData.dependant && userData.dependant.length > 0) {
            setFamilyMembers(userData.dependant);
        }
    }, [userData]);
    const handleModalClose = () => {
        setShow(false);
        handleClose();
    }

    useEffect(() => {
        if (isShow) {
            setShow(true);
        }
    }, [isShow]);

    const joinLoyaltyProgram = async (member: any) => {
        try {
            setJoinLoyaltyLoader(true);
            const success = await joinLoyalty(member?.id);
            setJoinLoyaltyLoader(false)
            if (success && success.status && success.status.error === false) {
                dispatch(patientDataPending({ uniqueId: userData?.uniqueId }));
            }
        } catch (err) {
            setJoinLoyaltyLoader(false);
            toast.error('We are unable to enrol you, please try again after sometime.')
        }
    };
    return (
        <>
            <Offcanvas placement="end" show={show} onHide={handleModalClose} >
                <div className="my_family_drawer d-flex flex-column overflow-hidden">
                    <div className="offcanvas-action d-flex">
                        <button
                            type="button"
                            className="btn btn-link p-0 fw-normal fs-7"
                            onClick={() => { setIsAddEditMember(true); setTitle('Add'); setSelectedMember('') }}>
                            Add new member</button>
                        <button type="button" className="btn btn-link p-0 fw-normal fs-7 ms-auto"
                            onClick={() => { handleModalClose() }}>Done</button>
                    </div>
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title">My Family</h5>
                    </div>
                    <div className="offcanvas-body">
                        <ul>
                            {
                                (userData && userData.id && familyMembers && familyMembers.length > 0) &&
                                familyMembers.map((member: any) => {
                                    if (userData.id !== member.loginId) {
                                        let letterOne = "", letterTwo = "";
                                        if (member.firstName) {
                                            letterOne = member.firstName.charAt(0);
                                        }
                                        if (member.lastName) {
                                            letterTwo = member.lastName.charAt(0);
                                        }
                                        return (
                                            <li key={member.uniqueId} className="d-flex justify-content-between border-bottom mb-3 pb-3 align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar avatar-lg rounded-circle flex-shrink-0 me-2">
                                                        {/* <img alt="Image placeholder" src="https://demos.creative-tim.com/argon-dashboard-pro/assets/img/theme/team-1.jpg" /> */}
                                                        {letterOne}{letterTwo}
                                                    </div>
                                                    <div className="av-txt">
                                                        <div className="av_title">{member.firstName + " " + member.lastName}</div>
                                                        <div className="av_subtitle opacity-50">{member.relation} | {(member?.memberId !== "0" && member?.memberId !== null && 
                                                        member?.memberId !== "null" && member?.memberId !== "") && `${member.memberId} | ${member.points} Pts.`}</div>
                                                    </div>
                                                </div>
                                                <div className='d-flex gap-2'>
                                                    {
                                                        joinLoyaltyLoader && 
                                                        <Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                    }
                                                    {
                                                        (member?.memberId === "0" || member?.memberId === null || 
                                                        member?.memberId === "null" || member?.memberId === "") && !joinLoyaltyLoader &&
                                                        <button type="button" className="btn btn-link p-0 fw-normal fs-7"
                                                        onClick={() => {  
                                                            joinLoyaltyProgram(member);
                                                        }}>
                                                            Join Program
                                                        </button>
                                                    }
                                                    <button type="button" className="btn btn-link p-0 fw-normal fs-7"
                                                        onClick={() => { setSelectedMember(member); setIsAddEditMember(true); setTitle('Edit') }}>
                                                        Edit
                                                    </button>
                                                </div>
                                            </li>
                                        )
                                    }
                                })
                            }
                        </ul>
                    </div>
                </div>
            </Offcanvas>
            {/* <AddNewMember member={selectedMember} isShow={isAddEditMember} title={title} handleClose={() => {
                setIsAddEditMember(false)
                 dispatch(patientDataPending({
                    uniqueId: userData.uniqueId
                }));
            }} /> */}
            <SharedOffCanvas
                isOpen={isAddEditMember}
                handleClose={() => { setIsAddEditMember(false) }}
                Children={AddEditMemberForm}
                handleSuccess={() => {
                    setIsAddEditMember(false)
                    dispatch(patientDataPending({
                        uniqueId: userData.uniqueId
                    }));
                }}
                title={title}
                member={selectedMember}
            />
        </>
    )
}
