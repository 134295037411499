import React, { useState, useEffect } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { fetchInviteList } from '../../../services/refer';
import { SharedOffCanvas } from '../../sharedOffCanvas/SharedOffCanvas';
import { ReferByEmailSms } from '../xcarepoints/ReferByEmailSms';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../../store/loadingIndicator/actions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

export const ReferFriendList = ({handleModalClose, handleSuccess}:any) => {

    const [isShowReferByEmailSms, setIsShowReferByEmailSms] = useState(false);
    const [referrals, setReferrals]:any = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        getInviteList();
    }, []);
    
    const getInviteList = () => {
        dispatch(fetchLoadingPending());
        fetchInviteList().then((success) => {
            dispatch(fetchLoadingSuccess(false));
            if (success && success.status && success.status.error === false) {
                console.log('successList', success);
                setReferrals(success);
            }
        }).catch((err) => {
            dispatch(fetchLoadingSuccess(false));
        });
    }

    return (
        <>
                <div className="offcanvas-action d-flex">
                    <button className="btn btn-link p-0 fw-normal fs-7" type="button" onClick={() => { setIsShowReferByEmailSms(true) }}>Refer by Email or SMS</button>
                    <button type="button" className="btn btn-link p-0 fw-normal fs-7 ms-auto"
                        // data-bs-dismiss="offcanvas" aria-label="Close"
                        onClick={() => { handleModalClose() }}>
                        Done
                    </button>
                </div>

                <div className="offcanvas-header">
                    <h5 className="offcanvas-title">Referrals</h5>
                </div>

                <div className="offcanvas-other">
                    <div className="card px-4 py-3 bg-light">
                        <div className="d-flex justify-content-between">
                            <div>
                                <div className="fs-7">
                                    Your referral reward points
                                </div>
                                <div className="fs-4 fw-medium text-danger">
                                    {referrals && referrals.referralPoints} pt.
                                </div>
                            </div>
                            <div>
                                <button type="button" className="btn btn-link p-0 fw-normal fs-7" >
                                    Redeem
                                </button>
                            </div>
                        </div>
                    </div>



                </div>

                <div className="offcanvas-body">
                <ul className="list-group list-group-flush">
                    {
                        (referrals && referrals.referrals && referrals.referrals.length > 0) &&
                        referrals.referrals.map((referral: any) => {
                            return (
                                <li key={referral.id} className="list-group-item d-flex justify-content-between align-items-start px-0">
                                    <div className="me-auto">
                                        <div className="fw-medium">{referral.referralOn}</div>
                                        {/* <span className="fs-7 opacity-50">seanjackson@gmail.com</span> */}
                                    </div>
                                    {
                                        referral.attempt ?
                                            <div>
                                                <span className="badge rounded-pill bg-secondary me-2">Accepted</span>
                                            </div>
                                            :
                                            <div>
                                                {/* <span className="badge rounded-pill bg-dark me-2">Resend</span> */}
                                                <span className="badge rounded-pill bg-danger me-2">Pending</span>
                                            </div>
                                    }
                                </li>
                            )
                        })
                    }
                        {/* <li className="list-group-item d-flex justify-content-between align-items-start px-0">
                            <div className="me-auto">
                                <div className="fw-medium">Sean Jackson</div>
                                <span className="fs-7 opacity-50">seanjackson@gmail.com</span>
                            </div>
                            <div>
                                <span className="badge rounded-pill bg-secondary me-2">Accepted</span>
                            </div>
                        </li>

                        <li className="list-group-item d-flex justify-content-between align-items-start px-0">
                            <div className="me-auto">
                                <div className="fw-medium">Heather Morales</div>
                                <span className="fs-7 opacity-50">+1 416 825 3186</span>
                            </div>
                            <div>
                                <span className="badge rounded-pill bg-dark me-2">Resend</span>
                                <span className="badge rounded-pill bg-danger me-2">Pending</span>
                            </div>
                        </li>

                        <li className="list-group-item d-flex justify-content-between align-items-start px-0">
                            <div className="me-auto">
                                <div className="fw-medium">Frances Nguyen</div>
                                <span className="fs-7 opacity-50">+1 416 321 7895</span>
                            </div>
                            <div>
                                <span className="badge rounded-pill bg-secondary me-2">Accepted</span>
                            </div>
                        </li>

                        <li className="list-group-item d-flex justify-content-between align-items-start px-0">
                            <div className="me-auto">
                                <div className="fw-medium">Daniel Palmer</div>
                                <span className="fs-7 opacity-50">daniel@gmail.com</span>
                            </div>
                            <div>
                                <span className="badge rounded-pill bg-secondary me-2">Accepted</span>
                            </div>
                        </li>

                        <li className="list-group-item d-flex justify-content-between align-items-start px-0">
                            <div className="me-auto">
                                <div className="fw-medium">Timothy West</div>
                                <span className="fs-7 opacity-50">+1 416 825 3186</span>
                            </div>
                            <div>
                                <span className="badge rounded-pill bg-secondary me-2">Accepted</span>
                            </div>
                        </li>

                        <li className="list-group-item d-flex justify-content-between align-items-start px-0">
                            <div className="me-auto">
                                <div className="fw-medium">Martin Bradley</div>
                                <span className="fs-7 opacity-50">+1 416 825 3186</span>
                            </div>
                            <div>
                                <span className="badge rounded-pill bg-dark me-2">Resend</span>
                                <span className="badge rounded-pill bg-danger me-2">Pending</span>
                            </div>
                        </li>

                        <li className="list-group-item d-flex justify-content-between align-items-start px-0">
                            <div className="me-auto">
                                <div className="fw-medium">Frances Nguyen</div>
                                <span className="fs-7 opacity-50">+1 416 321 7895</span>
                            </div>
                            <div>
                                <span className="badge rounded-pill bg-secondary me-2">Accepted</span>
                            </div>
                        </li>

                        <li className="list-group-item d-flex justify-content-between align-items-start px-0">
                            <div className="me-auto">
                                <div className="fw-medium">Daniel Palmer</div>
                                <span className="fs-7 opacity-50">daniel@gmail.com</span>
                            </div>
                            <div>
                                <span className="badge rounded-pill bg-secondary me-2">Accepted</span>
                            </div>
                        </li> */}
                    </ul>

                </div>
            {/* <ReferByEmailSms isShow={isShowReferByEmailSms} handleClose={() => { setIsShowReferByEmailSms(false) }} /> */}
            <SharedOffCanvas
                    isOpen={isShowReferByEmailSms}
                    handleClose={() => { setIsShowReferByEmailSms(false) }}
                    Children={ReferByEmailSms}
                    handleSuccess={() => {
                        setIsShowReferByEmailSms(false)
                    }}
                />
        </>
    )
}
