import React, { useState, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Table } from 'react-bootstrap';
import { claimLoyalty, getClaimAppointmentList, getLoyaltyActionList } from '../../../services/loyalty';
import moment from 'moment';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../../store/loadingIndicator/actions';
import { SharedOffCanvas } from '../../sharedOffCanvas/SharedOffCanvas';
import { RedeemPoints } from './ReedemPoints';

export const LoyaltyActivity = ({handleModalClose, handleSuccess}:any) => {
    const [showClaim, setShowClaim] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showReedemPoints, setShowRedeemPoints] = useState(false);
    const [list, setList] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [status, setStatus] = useState('All');
    const [currentRecord, setShowCurrentRecord] = useState(null);
    const patientData = useSelector((state: any) => state.patientData.patientDataResponse, shallowEqual);
    const totalSum = useMemo(() => {
        let sum = 0;
        patientData?.dependant?.forEach((item: any) => {
            if (item.points && Number.isFinite(Number(item.points))) {
                sum += Number(item.points);
            }
        });
        return sum;
    }, [patientData]);
    const currentDependant = useMemo(() => {
        const ID = patientData.id;
        return patientData?.dependant?.find((item: any) => Number(item.loginId) === Number(ID));
    }, [patientData]);
    const joinLoyaltyProgram = async () => {
        try {
            let queryPrams = `?`;
            setIsLoading(true);
            if (startDate) {
                queryPrams += `startDate=${moment(startDate).format('DD-MM-YYYY')}&endDate=${moment(startDate).add(30, 'days').format('DD-MM-YYYY')}&`
            }
            if (status) {
                queryPrams += `status=${status}`
            }
            const success = await getLoyaltyActionList(currentDependant?.id, queryPrams);
            setIsLoading(false);
            if (success && success.status && success.status.error === false) {
                setList(success.data);
            }
        } catch (err) {
            setIsLoading(false);
            console.log(err);
        }
    };
    useEffect(() => {
        joinLoyaltyProgram();
    },[startDate, status]);

    const onClickClaim = (item: any) => {
        setShowCurrentRecord(item);
        setShowClaim(true);
    }


    return (
        <>
            <div className="offcanvas-action d-flex">
                <button type="button" className="btn btn-link p-0 fw-normal fs-7 ms-auto" onClick={() => { handleModalClose() }}>
                    Done
                </button>
            </div>

            <div className="offcanvas-header">
                <h5 className="offcanvas-title">Points Activity</h5>
            </div>

            <div className="offcanvas-body">
                <div className='d-flex justify-content-between mb-4'>
                    <div className='d-flex flex-column'>
                        <h6 className='mb-1'>Membership Rewards</h6>
                        <label>{totalSum}</label>
                    </div>
                    <div>
                        <button type="button" className="btn btn-link p-0 fw-normal fs-7" onClick={() => {  
                            setShowRedeemPoints(true);
                        }}>
                            Redeem Points
                        </button>
                    </div>
                </div>
                <div className='d-flex gap-2 mb-4'>
                    <input type="date" className='form-control' value={startDate} onChange={(event) => {
                        setStartDate(event.target.value);
                    }} />
                    <select className='form-control form-select' value={status} onChange={(event) => {
                        setStatus(event.target.value);
                    }}>
                        <option value='All'>All</option>
                        <option value='Allocated'>Allocated</option>
                        <option value='Claimed'>Claimed</option>
                        <option value='Earned'>Earned</option>
                        <option value='Redeemed'>Redeemed</option>
                    </select>
                </div>
            {
                isLoading ? 'Loading...' :
                <>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Description</th>
                            <th>Points</th>
                            <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                list?.map((item: any) => {
                                    return (
                                        <tr>
                                            <td>{moment(item.createdAt).format("DD MMM, YYYY")}</td>
                                            <td style={{
                                                width: '100px'
                                            }}>{item.status}</td>
                                            <td>{item.description}</td>
                                            <td>{item.points}</td>
                                            <td>
                                            {
                                                item?.action === "Claim" ?
                                                <button type="button" className="btn btn-link p-0 fw-normal fs-7" onClick={() => {  
                                                    onClickClaim(item);
                                                    // handleClaim(item);
                                                }}>
                                                    Claim
                                                </button> : item.action
                                            }</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </>
            }
            </div>
            <SharedOffCanvas
                isOpen={showClaim}
                handleClose={() => { setShowClaim(false); setShowCurrentRecord(null); }}
                Children={ClaimDialog}
                handleSuccess={() => {
                    setShowClaim(false); setShowCurrentRecord(null);
                }}
                style={{
                    width: '70vw'
                }}
                item={currentRecord}
                joinLoyaltyProgram={joinLoyaltyProgram}
                currentDependant={currentDependant}
                uniqueId={patientData?.uniqueId}
            />

            <SharedOffCanvas
                isOpen={showReedemPoints}
                handleClose={() => { setShowRedeemPoints(false) }}
                Children={RedeemPoints}
                handleSuccess={() => {
                    setShowRedeemPoints(false)
                }}
                style={{
                    width: '90vw'
                }}
            />
        </>
    )
};

const ClaimDialog = ({ handleModalClose, item, joinLoyaltyProgram, currentDependant, uniqueId }: any) => {
    const [fileName, setFileName] = useState<any>(null);
    const [secondFileName, setSecondFileName] = useState<any>(null);
    const [appointmentList, setAppointmentList] = useState([]);
    const [selectedAppointment, setSelectedAppointment] = useState('');
    const dispatch = useDispatch();
    const isAppointmentValid = useMemo(() => {
        if (item?.type === "NEW APPOINTMENT" || item?.type === "CAMPAIGN") {
            if (selectedAppointment === '') return false;
            return true;
        } else {
            return true;
        }
    }, [item, selectedAppointment]);
    console.log("isAppointmentValid", isAppointmentValid);
    const handleOnChangeFile = (files: any) => {
        if (files && files[0] && files[0].size && files[0].size > 104857600) {
            toast.error('Max file size 100 MB.');
            return;
        }
        if (files && files[0] && files[0].name) {
            setFileName(files[0]);
        }
    }
    const handleOnChangeSecondFile = (files: any) => {
        if (files && files[0] && files[0].size && files[0].size > 104857600) {
            toast.error('Max file size 100 MB.');
            return;
        }
        if (files && files[0] && files[0].name) {
            setSecondFileName(files[0]);
        }
    }
    const onSubmit = async () => {
        try {
            dispatch(fetchLoadingPending());
            const data = {
                dependantId: item?.dependantId,
                actionId: item.actionId,
                receipt1: fileName,
                // receipt2: secondFileName
            };
            if (item?.type === "NEW APPOINTMENT" || item?.type === "CAMPAIGN") {
                Object.assign(data, {
                    appointmentId: selectedAppointment
                });
            }
            const success = await claimLoyalty(uniqueId, data);
            dispatch(fetchLoadingSuccess(false));
            if (success && success.status && success.status.error === false) {
                joinLoyaltyProgram();
                toast.success(success?.status?.msg);
                handleModalClose();
            }
        } catch (err) {
            dispatch(fetchLoadingSuccess(false));
            toast.error('We are unable to claim your points, please try again after sometime.')
        }
    }

    const getAppointmentList = async () => {
        if (item?.type === "NEW APPOINTMENT" || item?.type === "CAMPAIGN") {
            try {
                const result = await getClaimAppointmentList(item?.dependantId, item?.actionId);
                if (result?.data) {
                    setAppointmentList(result?.data);
                }
            } catch (error) {

            }
        }
    }

    useEffect(() => {
        getAppointmentList();
    }, [item]);
    return (
        <>
            <div className="offcanvas-action d-flex">
                <button type="button" className="btn btn-link p-0 fw-normal fs-7 ms-auto"
                    onClick={() => { handleModalClose() }}>
                    Cancel
                </button>
            </div>

            <div className="offcanvas-header">
                <h5 className="offcanvas-title">Upload Completed Form</h5>
            </div>

            <div className="offcanvas-body">

                <div className="alert alert-warning mb-4 text-center" role="alert">
                    Please upload your completed form here. Make sure all the mandatory fields are completed before you upload the form. Thank you !!!
                </div>

                {
                    (item?.type === "NEW APPOINTMENT" || item?.type === "CAMPAIGN") &&
                    <div className="mb-3">
                        <div className="fs-7 opacity-50 mb-2">Choose Appointment</div>
                        <select className='form-select w-100 fw-normal' value={selectedAppointment} onChange={(event) => {
                            setSelectedAppointment(event.target.value);
                        }}>
                            <option value=''>Select</option>
                            {
                                appointmentList?.map((item: any) => {
                                    return (
                                        <option value={item?.id}>{item.fullname} {item?.date} {item?.time}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                }
                

                <div className="mb-3">
                    <div className="fs-7 opacity-50 mb-2">{fileName ? fileName?.name:''}</div>
                    <input type="file" className="btn btn-upload w-100 fw-normal" placeholder="Upload claim picture" onChange={(e:any)=>{handleOnChangeFile(e.target.files)}} />
                    <div className="fs-7 opacity-50 text-end mt-2">Max file size 100 MB.</div>
                </div>

                {/* <div className="mb-3">
                    <div className="fs-7 opacity-50 mb-2">{secondFileName ? secondFileName?.name:''}</div>
                    <input type="file" className="btn btn-upload w-100 fw-normal" placeholder="Upload claim picture" onChange={(e:any)=>{handleOnChangeSecondFile(e.target.files)}} />
                    <div className="fs-7 opacity-50 text-end mt-2">Max file size 100 MB.</div>
                </div> */}

                <div className="mb-3">
                    <button disabled={!fileName || !isAppointmentValid} type="button" className="btn btn-dark w-100 fw-normal" onClick={()=>{onSubmit()}}>
                        Submit
                    </button>
                </div>
            </div>
        </>
    )
}
