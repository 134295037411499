import React, { useEffect, useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas';
import Stack from 'react-bootstrap/Stack';
import { SharedOffCanvas } from '../../sharedOffCanvas/SharedOffCanvas';
import { EditCoverage } from './EditCoverage';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../../store/loadingIndicator/actions';
import { EditCoverageExpence, fetchCoverageDetails } from '../../../services/insuranceCalculator';
import { allowAlphabetsAndSpace, allowOnlyDigit } from '../../../../utils/validators';

export const ViewCoverage = ({ handleModalClose, handleSuccess, dependant, coverageData,handleApiCall }: any) => {

    const [isShowEditCoverage, setIsShowEditCoverage] = useState(false);
    const [selectedCoverage, setSelectedCoverage]: any = useState('');
    const [coverageDetails, setCoverageDetails]: any = useState([]);
    const dispatch = useDispatch();
    const [isEditExpance, setIsEditExpance] = useState(false);
    const [selectedExpance, setSelectedExpance]: any = useState('');
    const [expance, setExpance]:any = useState('');

    useEffect(() => {
        if (coverageData) {
           fetchCoverageData();
       } 
    },[]);
    
    const handleEditSuccess = () => {
        fetchCoverageData();
    }
    const fetchCoverageData = () => {
        dispatch(fetchLoadingPending());
        fetchCoverageDetails(dependant.id).then((success: any) => {
            dispatch(fetchLoadingSuccess(false));
            if (success && success.status && success.status.error === false) {
                console.log('successCoverage', success);
                setCoverageDetails(success.coverage);
            }
        }).catch((err: any) => {
            dispatch(fetchLoadingSuccess(false));
            if (err.error) {
                setCoverageDetails([]);
            }
            console.log(err);
        })
    }

    const handleEditExpance = (exp?: any) => {
        if (exp) {
            setIsEditExpance(true);
            setSelectedExpance(exp);
            setExpance(exp.expense)
        } else {
            setIsEditExpance(false);
            setSelectedExpance('');
            setExpance('')
        }
    }
    const onChangeExpance = (e:any) => {
        if (allowOnlyDigit(e.target.value) || e.target.value === '') {
            setExpance(e.target.value)
        }
    }
    const onUpdate = () => {
        dispatch(fetchLoadingPending());
        EditCoverageExpence({amount:expance},selectedExpance.id).then((success: any) => {
            dispatch(fetchLoadingSuccess(false));
            if (success && success.status && success.status.error === false) {
                console.log('successCoverage', success);
                handleEditExpance();
                fetchCoverageData();
                handleApiCall();
            }
        }).catch((err: any) => {
            dispatch(fetchLoadingSuccess(false));
            console.log(err);
        })
    }
    return (
        <>
            <div className="offcanvas-action d-flex">
                <button type="button" className="btn btn-link p-0 fw-normal fs-7 ms-auto"
                    onClick={() => { handleModalClose() }}>
                    Done
                </button>
            </div>
            <div className="offcanvas-header">
                <h5 className="offcanvas-title">All Coverage</h5>
            </div>
            <div className="offcanvas-body">
                <div className="coverages_list">
                    {
                        (coverageDetails && coverageDetails.length > 0) &&
                        coverageDetails.map((coverage: any) => {
                            return (
                                <React.Fragment key={coverage.id + coverage.name}>
                                    <div className="d-flex align-items-start justify-content-between mb-2">
                                        <div className="coverage_name">
                                            {coverage.name} Coverage
                                        </div>
                                        <div>
                                            <button type="button" className="btn btn-link p-0 fw-normal fs-7" onClick={() => { setIsShowEditCoverage(true);setSelectedCoverage(coverage) }}>
                                                Edit
                                            </button>
                                        </div>
                                    </div>
                                    {
                                        (coverage && coverage.details.length > 0) &&
                                        coverage.details.map((item: any) => {
                                            return (
                                                <div key={item.id + item.name} className="coverage_item">
                                                    <div className="d-flex justify-content-end fs-5 fw-medium">
                                                        ${item.totalExpense ? item.totalExpense : 0}.00
                                                    </div>
                                                    <div className="progress my-2" style={{ height: "3px" }} >
                                                        <div className="progress-bar" style={{ width: (item.totalExpense / item.amount) * 100 + "%" }} ></div>
                                                    </div>
                                                    <div className="d-flex align-items-start justify-content-between">
                                                        <div className="fs-7 opacity-50">{item.name}</div>
                                                        <div className="fs-7 opacity-50">${item.amount}.00</div>
                                                    </div>
                                                    <div className="spent_data mt-3 mb-4">
                                                        {
                                                            (item.expenses && item.expenses.length > 0) &&
                                                            <>
                                                                <Stack direction="horizontal" gap={3} className="mb-2">
                                                                    <div className="fs-7 opacity-50">Clinic</div>
                                                                    <div className="fs-7 opacity-50 ms-auto">Spent</div>
                                                                </Stack>
                                                                {
                                                                    item.expenses.map((expence: any, index: any) => {
                                                                        return (
                                                                            <>
                                                                                <Stack key={expence.id + item.name} direction="horizontal" gap={3} className="mb-2 pb-2 border-bottom">
                                                                                    <div>{expence.name}</div>
                                                                                    <div className="ms-auto">${expence.expense}.00</div>
                                                                                    {
                                                                                        !isEditExpance &&
                                                                                        <button className="btn btn-link p-0 fw-normal fs-7" onClick={() => {handleEditExpance(expence)}}>Edit</button>
                                                                                    }
                                                                                    {
                                                                                        (expence.id === selectedExpance.id) && isEditExpance &&
                                                                                        <div>
                                                                                            <button className="btn p-0 btn-link me-2 text-secondary" onClick={() => { handleEditExpance()}}><i className="bi bi-x-circle-fill text-danger"></i></button>
                                                                                            <button disabled={!expance} className="btn p-0 btn-link" onClick={()=>{onUpdate()}}><i className="bi bi-check-circle-fill text-success"></i></button>
                                                                                        </div>
                                                                                    }
                                                                                </Stack>
                                                                                {
                                                                                    (expence.id === selectedExpance.id) && isEditExpance &&
                                                                                    <div className="mb-3">
                                                                                        <label className="form-label">Expense</label>
                                                                                        <input type="text" className="form-control" id="" name="expance" value={expance} onChange={onChangeExpance} />
                                                                                    </div>
                                                                                }
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </React.Fragment>
                            )
                        })
                    }
                </div>
            </div>
            <SharedOffCanvas
                isOpen={isShowEditCoverage}
                handleClose={() => { setIsShowEditCoverage(false) }}
                Children={EditCoverage}
                handleSuccess={() => {
                    setIsShowEditCoverage(false);
                    handleEditSuccess();
                    handleApiCall();
                }}
                dependant={dependant}
                coverageDetails={selectedCoverage}

            />
        </>
    )
}
