import React, { useMemo, useState } from "react";
import { SharedOffCanvas } from "../../sharedOffCanvas/SharedOffCanvas";
import { LoyaltyActivity } from "./LoyaltyActivity";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { joinLoyalty } from "../../../services/loyalty";
import { patientDataPending } from "../../../../store/patientData/actions";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

export const XcarePoints = (props: any) => {
  const [isShowReferFriend, setIsShowReferFriend] = useState(false);
  const [joinLoyaltyLoader, setJoinLoyaltyLoader] = useState(false);
  const patientData = useSelector(
    (state: any) => state.patientData.patientDataResponse,
    shallowEqual
  );
  const dispatch = useDispatch();
  const currentDependant = useMemo(() => {
    const ID = patientData.id;
    return patientData?.dependant?.find(
      (item: any) => Number(item.loginId) === Number(ID)
    );
  }, [patientData]);
  const totalSum = useMemo(() => {
    let sum = 0;
    patientData?.dependant?.forEach((item: any) => {
      if (item.points && Number.isFinite(Number(item.points))) {
        sum += Number(item.points);
      }
    });
    return sum;
  }, [patientData]);
  const joinLoyaltyProgram = async () => {
    try {
      setJoinLoyaltyLoader(true);
      const success = await joinLoyalty(currentDependant?.id);
      setJoinLoyaltyLoader(false);
      if (success && success.status && success.status.error === false) {
        dispatch(patientDataPending({ uniqueId: patientData?.uniqueId }));
      }
    } catch (err) {
      setJoinLoyaltyLoader(false);
      toast.error(
        "We are unable to enrol you, please try again after sometime."
      );
    }
  };
  return (
    <>
      <div className="card_item rew_loy_point card p-3">
        <div className="infocard_header mb-2">
          <div className="card_ititle">My Rewards</div>
        </div>
        <div className="infocard-body p-1">
          <div className="av-txt">Account #{currentDependant?.memberId}</div>
          <div className="av-txt pt-2">Points: {patientData?.totalPoints}</div>
        </div>
        <div className="card_ilink mt-5">
          {joinLoyaltyLoader ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <div className="card_ilink fix_bottom">
              <button
                type="button"
                className="btn btn-link p-0 fw-normal fs-7"
                onClick={() => {
                  joinLoyaltyProgram();
                }}
              >
                View points activity
              </button>
            </div>
          )}
        </div>
      </div>
      <SharedOffCanvas
        isOpen={isShowReferFriend}
        handleClose={() => {
          setIsShowReferFriend(false);
        }}
        Children={LoyaltyActivity}
        handleSuccess={() => {
          setIsShowReferFriend(false);
        }}
        style={{
          width: "70vw",
        }}
      />
    </>
  );
};
