import React from 'react';
import Helmet from 'react-helmet';
import { TITLES_LABELS, APPOINTMENT_STATUS, CHAT } from '../../constants';
import apiConfigs from '../../configs/apiConfigs';
import { Appointment, PatientDataState, PatientDataRequest } from '../../../store/patientData/types';
import { connect } from 'react-redux';
import { IAppState } from '../../../store';
import { AppointmentDetailRequest, AppointmentDetailState, AppointmentDetailResponse, ChangeAppointmentRemiderRequest } from '../../../store/appointments/types';
import { appointmentDetailPending, changeAppointmentRemiderPending } from '../../../store/appointments/actions';
import { toast } from 'react-toastify';
import { showAddress } from '../../../components/renderAddress';
import { loadMap, setMarkerToPostion, setGoogleMap } from '../../utils/googleFunction';
import { NavLink, Redirect } from 'react-router-dom';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import $ from 'jquery';
import { Alert, Modal } from 'react-bootstrap'
import moment from 'moment';
import { getCookie } from '../../utils';
import { patientDataPending } from '../../../store/patientData/actions';
import nl2br from 'react-nl2br';
import { Avatar } from '@material-ui/core';
import { userLogout } from '../../../store/auth/actions';
import { Header } from '../../components/header/Header';
interface Props {
    location: any;
    match: any;
    appointmentDetailState: AppointmentDetailState | any;
    appointmentDetail: typeof appointmentDetailPending;
    changeAppointmentReminder: typeof changeAppointmentRemiderPending;
    patientDataState: PatientDataState;
    patientData: typeof patientDataPending;
    logout: typeof userLogout;
    history: any;
}
interface State {
    appointmentDetail?: AppointmentDetailResponse | any;
    viewPhone: boolean;
    chatArray: any[];
    chatMessageInput: string;
    appointmentCancelled: boolean;
    allowReschedule: boolean;
    doNotShowInput: boolean;
    appointmentCompleted: boolean;
    viewMobileDetail: boolean;
}
class AppointmentDetail extends React.Component<Props, State> {
    googleMap: any;
    availabilities = [{
        time: "Before 9am",
        img: "images/early.svg",
        selected: false,
        value: "Early"
    }, {
        time: "9am - 12pm",
        img: "images/morning.svg",
        selected: false,
        value: "Morning"
    }, {
        time: "12pm - 2pm",
        img: "images/noon.svg",
        selected: false,
        value: "noon"
    }, {
        time: "2pm - 5pm",
        img: "images/afternoon.svg",
        selected: false,
        value: "afternoon"
    }, {
        time: "After 5pm",
        img: "images/evening.svg",
        value: "evening"
    }, {
        time: "Sat - Sun",
        img: "images/weekends.svg",
        selected: false,
        value: "weekend"
    }];
    db: any;
    messagesEnd: any;
    unsubscribe: any;
    calledOnce: boolean = false;
    constructor(props: any) {
        super(props);
        this.state = {
            viewPhone: false,
            chatMessageInput: '',
            chatArray: [], appointmentCancelled: false, allowReschedule: false,
            doNotShowInput: false, appointmentCompleted: false, viewMobileDetail: false
        }
        this.reScheduleAppointment = this.reScheduleAppointment.bind(this);
    }
    componentDidMount() {
        this.db = firebase.firestore();
        const { appointmentId } = this.props.match.params;
        if (appointmentId) {
            this.props.appointmentDetail({ appointmentUniqueId: appointmentId });
        }
        this.loadMap();
        // this.getChatData();
    }
    async loadMap() {
        try {
            const result = await loadMap();
        } catch (error) {
            console.log(error);
        }
    }
    setMapAndMarker(response: any) {
        if (this.googleMap) {
            if (response.clinic.address && response.clinic.address.latitude && response.clinic.address.longitude) {
                setMarkerToPostion(this.googleMap, { latitude: response.clinic.address.latitude, longitude: response.clinic.address.longitude });
            } else {
                setMarkerToPostion(this.googleMap, { latitude: 23.41, longitude: 72.12 });
            }
        } else {
            if (response && response.clinic && response.clinic.address && response.clinic.address.latitude && response.clinic.address.longitude) {
                const latitude = parseFloat(response.clinic.address.latitude);
                const longitude = parseFloat(response.clinic.address.longitude);
                setGoogleMap({ latitude: latitude, longitude: longitude }, 'map')?.then((success: any) => {
                    this.googleMap = success;
                    setMarkerToPostion(this.googleMap, { latitude: latitude, longitude: longitude });
                }).catch((err) => {
                    console.log(err);
                });
            } else {
                setGoogleMap({ latitude: 23.41, longitude: 71.23 }, 'map')?.then((success) => {
                    this.googleMap = success;
                    setMarkerToPostion(this.googleMap, { latitude: 23.41, longitude: 72.12 });
                }).catch((err) => {
                    console.log(err);
                });
            }
        }
    }
    shouldComponentUpdate(nextProps: Props, nextState: State) {
        if (this.props !== nextProps) {
            if (nextProps.appointmentDetailState.error !== null) {
                toast.error('Something went wrong.');
            } else {
                if (nextProps.appointmentDetailState.pending === false) {
                    if (nextProps.appointmentDetailState.appointmentDetailResponse && nextProps.appointmentDetailState.appointmentDetailResponse.status
                        && nextProps.appointmentDetailState.appointmentDetailResponse.status.error === false) {
                        this.setState({ appointmentDetail: nextProps.appointmentDetailState.appointmentDetailResponse }, () => {
                            if (this.state.appointmentDetail) {
                                this.appointmentStatuShow(this.state.appointmentDetail.currentStatus.toUpperCase());
                            }
                        });
                        /* if (nextProps.appointmentDetailState.appointmentDetailResponse.currentStatus === APPOINTMENT_STATUS.CANCEL[0]) {
                            this.setState({
                                appointmentCancelled: true,
                                doNotShowInput: true,
                            });
                        } */
                        if (!this.calledOnce) {
                            this.calledOnce = true;
                            this.getChatData();
                        }
                        try {
                            if (nextProps.appointmentDetailState.appointmentDetailResponse.metadata.appointment.cancellation.allowRescheduling
                                && nextProps.appointmentDetailState.appointmentDetailResponse.currentStatus === APPOINTMENT_STATUS.REQUEST[0]) {
                                this.setState({ allowReschedule: false });
                            } else if (nextProps.appointmentDetailState.appointmentDetailResponse.metadata.appointment.cancellation.allowRescheduling &&
                                nextProps.appointmentDetailState.appointmentDetailResponse.currentStatus === APPOINTMENT_STATUS.CANCEL[0]) {
                                this.setState({ allowReschedule: true });
                            }
                        } catch (error) {

                        }
                        this.setMapAndMarker(nextProps.appointmentDetailState.appointmentDetailResponse);
                        nextProps.appointmentDetailState.appointmentDetailResponse.status.error = true;
                    }
                    if (nextProps.appointmentDetailState.changeApppointmentRemiderResponse &&
                        this.props.appointmentDetailState.changeApppointmentRemiderResponse !== nextProps.appointmentDetailState.changeApppointmentRemiderResponse
                        && nextProps.appointmentDetailState.changeApppointmentRemiderResponse.status
                        && nextProps.appointmentDetailState.changeApppointmentRemiderResponse.status.error === false) {
                        toast.success(nextProps.appointmentDetailState.changeApppointmentRemiderResponse.status.msg);
                    }
                }
            }
        }
        return true;
    }
    componentDidUpdate() {
        this.props.appointmentDetailState.error = null;
    }
    handleOpenMap = () => {
        const { latitude, longitude }: any = this.props.appointmentDetailState.appointmentDetailResponse.clinic.address;
        if (latitude && longitude) {
            let url = "https://maps.google.com/?q=" + latitude + "," + longitude;
            window.open(url);
        }
    }
    render() {
        if (this.props.appointmentDetailState.error !== null) {
            return (<Redirect to="/"></Redirect>);
        }
        const { appointmentId } = this.props.match.params;
        let url = '';
        if (this.state.appointmentDetail && this.state.appointmentDetail.doctor.photo && this.state.appointmentDetail.doctor.photo.name && this.state.appointmentDetail.doctor.photo.type) {
            url = apiConfigs.IMAGE_URL + 'doctor/' + this.state.appointmentDetail.doctor.photo.name + '-200x200.' + this.state.appointmentDetail.doctor.photo.type;
        }
        let status = '';
        if (this.state.appointmentDetail && this.state.appointmentDetail.currentStatus === APPOINTMENT_STATUS.REQUEST[0]) {
            status = APPOINTMENT_STATUS.REQUEST[0];
        } else if (this.state.appointmentDetail && this.state.appointmentDetail.currentStatus === APPOINTMENT_STATUS.SCHEDULE[0]) {
            if (this.state.appointmentDetail.metadata && this.state.appointmentDetail.metadata.appointment && this.state.appointmentDetail.metadata.appointment.confirmation && this.state.appointmentDetail.metadata.appointment.confirmation.status === "confirmed") {
                status = 'Confirmed'
            } else if (this.state.appointmentDetail.metadata && this.state.appointmentDetail.metadata.appointment && this.state.appointmentDetail.metadata.appointment.confirmation && this.state.appointmentDetail.metadata.appointment.confirmation.status === "cancelled") {
                status = 'Cancel Requested'
            } else {
                status = 'Scheduled'
            }
            // status = APPOINTMENT_STATUS.SCHEDULE[0];
        } else if (this.state.appointmentDetail && this.state.appointmentDetail.currentStatus === APPOINTMENT_STATUS.CANCEL[0]) {
            status = APPOINTMENT_STATUS.CANCEL[0];
        } else if (this.state.appointmentDetail && this.state.appointmentDetail.currentStatus === APPOINTMENT_STATUS.COMPLETE[0]) {
            status = APPOINTMENT_STATUS.COMPLETE[0];
        }
        let location = '';
        try {
            if (this.state.appointmentDetail && this.state.appointmentDetail.clinic && this.state.appointmentDetail.clinic.address) {
                location = this.state.appointmentDetail.clinic.address.sublocality.toLowerCase() + '-' + this.state.appointmentDetail.clinic.address.address_level_1_short.toLowerCase();
            }
        } catch (error) {
            console.log(error);
        }
        let checkedReminder = false;
        if (this.state.appointmentDetail && this.state.appointmentDetail.metadata && this.state.appointmentDetail.metadata.patient && this.state.appointmentDetail.metadata.patient.reminder) {
            checkedReminder = true;
        }
        let docName: any = [];
        if (this.state.appointmentDetail) {
            let doctorName = this.state.appointmentDetail.doctor.fullname.replace("Dr.", "");
            doctorName = doctorName.trim();
            docName = doctorName.split(" ");
        }
        const letterOne = docName[0] ? docName[0].charAt(0) : '';
        const letterTwo = docName[1] ? docName[1].charAt(0) : '';
        let pFullname = '';
        if (this.state.appointmentDetail && this.state.appointmentDetail.dependant && this.state.appointmentDetail.dependant.fname && this.state.appointmentDetail.dependant.lname) {
            pFullname = this.state.appointmentDetail.dependant.fname + " " + this.state.appointmentDetail.dependant.lname;
        }
        return (

            <>
                <Header></Header>
                <section className="appointment-detail-sec main-middle-sec without-sidebar">
                    <Helmet><title>{TITLES_LABELS.META.APPOINTMENT}</title></Helmet>
                    <div className="container pt-4">

                        <div className="d-flex justify-content-end mb-3">
                            <button className="btn btn-light d-none d-lg-block" onClick={() => {
                                // this.props.history.replace('/dashboard/upcoming');
                                this.props.history.goBack();
                            }}><i className="bi bi-arrow-left-short"></i> Back to home</button>
                            <button className="btn btn-primary d-block d-lg-none rounded-circle view-info-btn"><i className="bi bi-info-circle" onClick={() => {
                                this.setState({ viewMobileDetail: true });
                            }}></i></button>
                        </div>


                        <div className="row justify-content-center">
                            <div className="col-md-5 col-lg-4 d-none d-lg-block">
                                <div className="detail-sec">
                                    <div className="profile info-sec text-center">
                                        <div className="avatar avatar-lg img-circle m-auto">
                                            {
                                                url !== '' ?
                                                    <img src={url} className="img-fluid" /> :
                                                    <Avatar>{letterOne}{letterTwo}</Avatar>
                                            }
                                        </div>
                                        <div className="profile-info text-center">
                                            <div className="heading"><img src={require('../../../assets/images/handshake.svg')} />Trusted by Xcarepro</div>
                                            <h5>{this.state.appointmentDetail && this.state.appointmentDetail.doctor && this.state.appointmentDetail.doctor.fullname ? this.state.appointmentDetail.doctor.fullname : ''}</h5>
                                            <div><small className="text-muted">{this.state.appointmentDetail && this.state.appointmentDetail.clinic &&
                                                this.state.appointmentDetail.clinic.name} &nbsp;
                                                <label>{this.state.appointmentDetail && this.state.appointmentDetail.clinic && this.state.appointmentDetail.clinic.phone ? " - " + this.state.appointmentDetail.clinic.phone : ''}</label></small></div>
                                        </div>
                                    </div>
                                    {
                                        this.state.appointmentDetail && this.state.allowReschedule && this.state.appointmentDetail.date && this.state.appointmentDetail.time && moment().isBefore(moment(this.state.appointmentDetail.date + " " + this.state.appointmentDetail.time, 'YYYY-MM-DD LT')) &&
                                        <div className="info-sec">
                                            <h6 className="page-title">Reschedule Appointment</h6>
                                            <div className="d-flex request-listing">
                                                <div className="media-img me-2 text-center">
                                                    <img src={require('../../../assets/images/ic_notifications_active_24px.svg')} />
                                                </div>
                                                <div className="media-body">
                                                    <button onClick={(e) => {
                                                        if (this.state.appointmentDetail && moment().isBefore(moment(this.state.appointmentDetail.date + " " + this.state.appointmentDetail.time, 'YYYY-MM-DD LT'))) {
                                                            this.reScheduleAppointment(e)
                                                        }
                                                    }}>Reschedule</button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="info-sec">
                                        <div className="d-flex justify-content-between">
                                            <h6 className="page-title">Appointment details</h6>

                                            <div className="profile-info text-center">
                                                <span className="badge rounded-pill bg-primary text-capitalize">{status}</span>
                                            </div>
                                        </div>
                                        {
                                            (this.state.appointmentDetail && this.state.appointmentDetail &&
                                                this.state.appointmentDetail.appointmentType) &&
                                            <div className="d-flex request-listing">
                                                <div className="media-body">
                                                    <div className="desc-content">
                                                        {
                                                            this.state.appointmentDetail.appointmentType
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            (this.state.appointmentDetail && this.state.appointmentDetail &&
                                                this.state.appointmentDetail.appointmentType) &&
                                            <div className="d-flex request-listing">
                                                <div className="media-body">
                                                    <div className="desc-content">
                                                        {(this.state.appointmentDetail.time && this.state.appointmentDetail.date) &&
                                                            this.state.appointmentDetail.time + " (" + moment(this.state.appointmentDetail.date).format('MMM DD, YYYY') + ")"
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div className="mt-3">
                                            <h6 className="page-title">Appointment for</h6>
                                            <div>{pFullname ? pFullname : '-'}</div>
                                        </div>

                                    </div>
                                    <div className="info-sec">
                                        <h6 className="page-title">Provider</h6>
                                        <div className="d-flex request-listing justify-content-between">
                                            <div className="media-body me-2">
                                                <div className="desc-content">{this.state.appointmentDetail && this.state.appointmentDetail.doctor ? this.state.appointmentDetail.doctor.fullname : ''}</div>
                                            </div>
                                            <div className="media-img text-center">
                                                {
                                                    (this.state.appointmentDetail && this.state.appointmentDetail.doctor && this.state.appointmentDetail.doctor.fullname && this.state.appointmentDetail.doctor.providerId) &&
                                                    <a target={"_blank"} href={apiConfigs.SOURCE_URL + "dentists/" + location + "/" + this.state.appointmentDetail.doctor.fullname.toLowerCase().trim().replace(/[.,_+]/g, '').replace(/\s+/g, "-") + "/" + this.state.appointmentDetail.doctor.providerId + "/d"}>
                                                        <img src={require('../../../assets/images/ic_visibility_24px.svg')} className="me-1" />
                                                        View Profile
                                                    </a>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                    <div className="info-sec">
                                        <h6 className="page-title">Office Address</h6>
                                        <div className="desc-content">{this.state.appointmentDetail && this.state.appointmentDetail.clinic ? this.state.appointmentDetail.clinic.name : ''}</div>
                                        <div className="desc-content">
                                            {
                                                (this.state.appointmentDetail && this.state.appointmentDetail.clinic
                                                    && this.state.appointmentDetail.clinic.address) &&
                                                showAddress(this.state.appointmentDetail.clinic.address)
                                            }
                                        </div>
                                        <div id="map" className="map-sec" style={{ height: '150px', border: 0 }} onClick={this.handleOpenMap}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-8">
                                <div className="provider-chat">
                                    <div className="title-box">Conversation with {this.state.appointmentDetail && this.state.appointmentDetail.clinic ? this.state.appointmentDetail.clinic.name : ''}</div>
                                    <div className="chat-box" id="chatBox">
                                        {
                                            this.state.chatArray.map((value, index) => {
                                                console.log(value);
                                                if (value.actionType === CHAT.ACTION_TYPE.MESSAGE) {
                                                    return (

                                                        <div className={"chat " + (value.sender === CHAT.SENDER.PATIENT ? 'user' : '') + (value.sender === CHAT.SENDER.USER ? 'dr' : '') +
                                                            ((value.sender === CHAT.SENDER.PATIENT && this.props.patientDataState.patientDataResponse && value.senderId !== Number(this.props.patientDataState.patientDataResponse.uniqueId)) ? ' dark' : '')} key={index + Math.random()}>
                                                            <div className="chat-bubble">
                                                                {nl2br(value.text)}
                                                            </div>
                                                            <span className="msg_date">
                                                                {
                                                                    value &&
                                                                    value.createdAt &&
                                                                    value.createdAt.seconds &&
                                                                    <> {moment(moment.unix(value.createdAt.seconds)).format("DD MMMM, YYYY - LT")}
                                                                        {
                                                                            (value.senderName && value.senderName !== "") &&
                                                                            <> | Sent by {value.senderName}</>
                                                                        }
                                                                    </>
                                                                }
                                                            </span>
                                                            <div className="clear"></div>
                                                        </div>

                                                    );
                                                } else if (value.actionType === CHAT.ACTION_TYPE.STATUS) {
                                                    if (value.toStatus === CHAT.TO_STATUS.REQUESTED && value.fromStatus === CHAT.TO_STATUS.CANCELLED) {
                                                        return (
                                                            <div className="alertparent">
                                                                <Alert variant="warning" className="text-center" key={index + Math.random()}>
                                                                    <i className="far fa-calendar-check"></i> You indicated that you need to reschedule.
                                                                </Alert>
                                                            </div>
                                                        )
                                                    } else if (value.toStatus === CHAT.TO_STATUS.COMPLETED) {
                                                        return (
                                                            <div className="alertparent">
                                                                <Alert variant="success" className="text-center" key={index + Math.random()}>
                                                                    <i className="far fa-check-circle"></i> Appointment {value.toStatus.toLowerCase()}
                                                                    {
                                                                        value &&
                                                                        value.createdAt &&
                                                                        value.createdAt.seconds &&
                                                                        <>
                                                                            &nbsp;on&nbsp;
                                                                            {moment(moment.unix(value.createdAt.seconds)).format("DD MMMM, YYYY") + " at " + moment(moment.unix(value.createdAt.seconds)).format("LT")}</>
                                                                    }

                                                                </Alert>
                                                            </div>
                                                        )
                                                    } else if (value.toStatus === CHAT.TO_STATUS.CANCELLED) {
                                                        return (
                                                            <div className="alertparent">
                                                                <Alert variant="danger" className="text-center" key={index + Math.random()}>
                                                                    <i className="fas fa-exclamation-circle"></i>The Appointment has been
                                                                    <> {value.toStatus.toLowerCase()} </>
                                                                    <>{
                                                                        value &&
                                                                        value.createdAt &&
                                                                        value.createdAt.seconds &&
                                                                        <>
                                                                            &nbsp;on&nbsp;
                                                                            {moment(moment.unix(value.createdAt.seconds)).format("DD MMMM, YYYY") + " at " + moment(moment.unix(value.createdAt.seconds)).format("LT")}</>
                                                                    }</>
                                                                </Alert>
                                                            </div>
                                                        )
                                                    } else if (value.toStatus === CHAT.TO_STATUS.SCHEDULED) {
                                                        return (
                                                            <div className="alertparent">
                                                                <Alert variant="info" className="text-center" key={index + Math.random()}>
                                                                    <i className="far fa-calendar-check"></i> Appointment {value.toStatus.toLowerCase()}
                                                                    {
                                                                        value &&
                                                                        value.dateTime &&
                                                                        value.dateTime.date &&
                                                                        value.dateTime.time &&
                                                                        <>
                                                                            &nbsp;on&nbsp;
                                                                            {moment(value.dateTime.date).format("DD MMMM, YYYY") + " at " + moment(value.dateTime.date + " " + value.dateTime.time, 'YYYY-MM-DD LT').format("LT")}</>
                                                                    }
                                                                </Alert>
                                                            </div>
                                                        )
                                                    }
                                                } else if (value.actionType === CHAT.ACTION_TYPE.CHECKIN) {
                                                    return (
                                                        <Alert className="chat_checkin text-center" variant="light">
                                                            <img src={require('../../../assets/images/checkin_icon.svg')} className="" />
                                                            <strong>Checked in status:</strong> {value.text} <>{
                                                                value &&
                                                                value.createdAt &&
                                                                value.createdAt.seconds &&
                                                                <>
                                                                    &nbsp;at&nbsp;
                                                                    {moment(moment.unix(value.createdAt.seconds)).format("DD MMMM, YYYY") + " - " + moment(moment.unix(value.createdAt.seconds)).format("LT")}</>
                                                            }</>
                                                            {/* <Alert variant="success" className="text-center" key={index + Math.random()}>
                                                            <i className="far fa-check-circle"></i>&nbsp;
                                                        {
                                                                this.props.patientDataState.patientDataResponse ?
                                                                    <>
                                                                        {
                                                                            this.props.patientDataState.patientDataResponse.firstName + ' ' + this.props.patientDataState.patientDataResponse.lastName
                                                                        } {value.text}
                                                                    </> : ''
                                                            }
                                                        </Alert> */}
                                                        </Alert>
                                                    )
                                                } else if (value.actionType === CHAT.ACTION_TYPE.PRESCREENING) {
                                                    return (
                                                        <Alert className="chat_covid_alert text-center" variant="light">
                                                            <img src={require('../../../assets/images/covid-icon.svg')} className="" />
                                                            <strong>Pre-screening status:</strong> completed <>{
                                                                value &&
                                                                value.createdAt &&
                                                                value.createdAt.seconds &&
                                                                <>
                                                                    &nbsp;at&nbsp;
                                                                    {moment(moment.unix(value.createdAt.seconds)).format("DD MMMM, YYYY") + " - " + moment(moment.unix(value.createdAt.seconds)).format("LT")}</>
                                                            }</>
                                                            {/* <Alert variant="success" className="text-center" key={index + Math.random()}>
                                                            <i className="far fa-check-circle"></i>&nbsp;
                                                        {
                                                                this.props.patientDataState.patientDataResponse ?
                                                                    <>
                                                                        {
                                                                            this.props.patientDataState.patientDataResponse.firstName + ' ' + this.props.patientDataState.patientDataResponse.lastName
                                                                        } {"has filled covid-19 screening form"}
                                                                    </> : ''
                                                            }
                                                        </Alert> */}
                                                        </Alert>
                                                    )
                                                } else if (value.actionType === CHAT.ACTION_TYPE.PRESCREENING_STATUS) {
                                                    return (
                                                        <Alert className="chat_covid_alert text-center" variant="light" key={index + Math.random()}>
                                                            <img src={require('../../../assets/images/covid-icon.svg')} className="" />
                                                            <strong> Pre-screening status:</strong> verified <>{
                                                                value &&
                                                                value.createdAt &&
                                                                value.createdAt.seconds &&
                                                                <>
                                                                    &nbsp;at&nbsp;
                                                                    {moment(moment.unix(value.createdAt.seconds)).format("DD MMMM, YYYY") + " - " + moment(moment.unix(value.createdAt.seconds)).format("LT")}</>
                                                            }</>
                                                        </Alert>
                                                    )
                                                } else if (value.actionType === CHAT.ACTION_TYPE.PRESCREENING_REQUEST) {
                                                    return (
                                                        <Alert className="chat_covid_alert text-center" variant="light" key={index + Math.random()}>
                                                            <img src={require('../../../assets/images/covid-icon.svg')} className="" />
                                                            <strong> Pre-screening status:</strong> requested <>{
                                                                value &&
                                                                value.createdAt &&
                                                                value.createdAt.seconds &&
                                                                <>
                                                                    &nbsp;at&nbsp;
                                                                    {moment(moment.unix(value.createdAt.seconds)).format("DD MMMM, YYYY") + " - " + moment(moment.unix(value.createdAt.seconds)).format("LT")}</>
                                                            }</>
                                                        </Alert>
                                                    )
                                                } else if (value.actionType === CHAT.ACTION_TYPE.PAYMENT_REQUEST) {
                                                    return (
                                                        <Alert className="chat_covid_alert text-center" variant="light" key={index + Math.random()}>
                                                            <strong> {value.text} - </strong><>{
                                                                value &&
                                                                value.createdAt &&
                                                                value.createdAt.seconds &&
                                                                <>
                                                                    &nbsp;at&nbsp;
                                                                    {moment(moment.unix(value.createdAt.seconds)).format("DD MMMM, YYYY") + " - " + moment(moment.unix(value.createdAt.seconds)).format("LT")}</>
                                                            }</>
                                                        </Alert>
                                                    )
                                                } else if (value.actionType === CHAT.ACTION_TYPE.PAYMENT_SUCCESSFUL) {
                                                    return (
                                                        <Alert className="chat_covid_alert text-center" variant="light" key={index + Math.random()}>
                                                            <strong> You paid on </strong><>{
                                                                value &&
                                                                value.createdAt &&
                                                                value.createdAt.seconds &&
                                                                <>
                                                                    &nbsp;on&nbsp;
                                                                    {moment(moment.unix(value.createdAt.seconds)).format("DD MMMM, YYYY") + " - " + moment(moment.unix(value.createdAt.seconds)).format("LT")}</>
                                                            }</>
                                                        </Alert>
                                                    )
                                                } else if (value.actionType === CHAT.ACTION_TYPE.APPONINTMENT_ASSIGN) {
                                                    return (
                                                        <div className="alertparent">
                                                            <Alert variant="light" className="text-center" key={index + Math.random()}>
                                                                <strong>Appointment is assigned to {value.assignToEmail}</strong>
                                                            </Alert>
                                                        </div>
                                                    )
                                                } else if (
                                                    value.actionType ===
                                                    CHAT.ACTION_TYPE
                                                        .MESSAGE_CENTER_TEXT
                                                ) {
                                                    return (
                                                        <div
                                                            className="alert alert-light border py-2 text-center"
                                                            key={
                                                                index + Math.random()
                                                            }
                                                        >
                                                            <span>{value.text}</span>
                                                        </div>
                                                    );
                                                } else if (
                                                    value.actionType ===
                                                    CHAT.ACTION_TYPE.VOICE_CALL
                                                ) {
                                                    return (
                                                        <div
                                                            className="alert alert-light border py-2 text-center"
                                                            key={
                                                                index + Math.random()
                                                            }
                                                        >
                                                            <span>{value.text}</span>
                                                        </div>
                                                    );
                                                } else if (
                                                    value.actionType ===
                                                    CHAT.ACTION_TYPE
                                                        .CONFIRM_BY_PATIENT
                                                ) {
                                                    return (
                                                        <>
                                                            <div
                                                                className="alert alert-warning py-2 text-center"
                                                                key={
                                                                    index + Math.random()
                                                                }
                                                            >
                                                                <span>
                                                                    {" "}
                                                                    Appointment:
                                                                </span>{" "}
                                                                {value.text}{" "}
                                                                <>
                                                                    {value &&
                                                                        value.createdAt &&
                                                                        value.createdAt
                                                                            .seconds && (
                                                                            <>
                                                                                &nbsp;on&nbsp;
                                                                                {moment(
                                                                                    moment.unix(
                                                                                        value
                                                                                            .createdAt
                                                                                            .seconds
                                                                                    )
                                                                                ).format(
                                                                                    "DD MMMM, YYYY"
                                                                                ) +
                                                                                    " at " +
                                                                                    moment(
                                                                                        moment.unix(
                                                                                            value
                                                                                                .createdAt
                                                                                                .seconds
                                                                                        )
                                                                                    ).format(
                                                                                        "LT"
                                                                                    )}
                                                                            </>
                                                                        )}
                                                                </>
                                                            </div>
                                                        </>
                                                    );
                                                } else if (
                                                    value.actionType ===
                                                    CHAT.ACTION_TYPE
                                                        .CANCELLED_BY_PATIENT
                                                ) {
                                                    return (
                                                        <>
                                                            <div
                                                                className="alert alert-warning py-2 text-center"
                                                                key={
                                                                    index + Math.random()
                                                                }
                                                            >
                                                                <span>
                                                                    {" "}
                                                                    Appointment:
                                                                </span>{" "}
                                                                {value.text}{" "}
                                                                <>
                                                                    {value &&
                                                                        value.createdAt &&
                                                                        value.createdAt
                                                                            .seconds && (
                                                                            <>
                                                                                &nbsp;on&nbsp;
                                                                                {moment(
                                                                                    moment.unix(
                                                                                        value
                                                                                            .createdAt
                                                                                            .seconds
                                                                                    )
                                                                                ).format(
                                                                                    "DD MMMM, YYYY"
                                                                                ) +
                                                                                    " at " +
                                                                                    moment(
                                                                                        moment.unix(
                                                                                            value
                                                                                                .createdAt
                                                                                                .seconds
                                                                                        )
                                                                                    ).format(
                                                                                        "LT"
                                                                                    )}
                                                                            </>
                                                                        )}
                                                                </>
                                                            </div>
                                                        </>
                                                    );
                                                } else if (
                                                    value.actionType ===
                                                    CHAT.ACTION_TYPE.MESSAGE_TEXT
                                                ) {
                                                    return (
                                                        <div
                                                            className={
                                                                "chat " +
                                                                (value.sender ===
                                                                    CHAT.SENDER.PATIENT
                                                                    ? "user"
                                                                    : "") +
                                                                (value.sender ===
                                                                    CHAT.SENDER.USER
                                                                    ? "dr"
                                                                    : "") +
                                                                (value.sender ===
                                                                    CHAT.SENDER.PATIENT &&
                                                                    this.props
                                                                        .patientDataState
                                                                        .patientDataResponse &&
                                                                    value.senderId !==
                                                                    Number(
                                                                        this.props
                                                                            .patientDataState
                                                                            .patientDataResponse
                                                                            .uniqueId
                                                                    )
                                                                    ? " dark"
                                                                    : "")
                                                            }
                                                            key={
                                                                index + Math.random()
                                                            }
                                                        >
                                                            <div className="chat-bubble">
                                                                {nl2br(value.text)}
                                                            </div>
                                                            <span className="msg_date">
                                                                {value &&
                                                                    value.createdAt &&
                                                                    value.createdAt
                                                                        .seconds && (
                                                                        <>
                                                                            {" "}
                                                                            {moment(
                                                                                moment.unix(
                                                                                    value
                                                                                        .createdAt
                                                                                        .seconds
                                                                                )
                                                                            ).format(
                                                                                "DD MMMM, YYYY - LT"
                                                                            )}
                                                                        </>
                                                                    )}{" "}
                                                                - sms received
                                                            </span>
                                                            <div className="clear"></div>
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })
                                        }
                                    </div>

                                    <div className="input-box">
                                        <textarea name="" id="" placeholder="Type a message..." value={this.state.chatMessageInput} onChange={(e) => {
                                            this.setState({ chatMessageInput: e.target.value });
                                        }}></textarea>
                                        {
                                            <button className="enter-btn btn-custom rounded btn text-center" disabled={this.state.doNotShowInput} onClick={(e) => {
                                                this.onMessageSendClick(e);
                                            }}><img className="" src={require('../../../assets/images/send.svg')} /></button>
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* Div is shown when clicked on eye icon */}
                            {
                                this.state.viewMobileDetail &&

                                <Modal className="in-mob-view" show={true} onHide={() => {
                                    this.setState({ viewMobileDetail: false });
                                }}>
                                    <Modal.Header>
                                        {/* <button className="btn btn-primary d-block d-lg-none rounded-circle view-info-btn">
                                            <i className="bi bi-patch-check" onClick={() => {
                                                this.setState({ viewMobileDetail: false });
                                            }}></i>
                                        </button> */}

                                        <button type="button" className="close btn-close" onClick={() => {
                                            this.setState({ viewMobileDetail: false });
                                        }}></button>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="detail-sec d-lg-none d-block my-3 infoViewOnly">
                                            <div className="profile info-sec text-center">
                                                <div className="avatar avatar-lg img-circle m-auto">
                                                    {
                                                        url !== '' ?
                                                            <img src={url} className="img-fluid" /> :
                                                            <Avatar>{letterOne}{letterTwo}</Avatar>
                                                    }
                                                </div>
                                                <div className="profile-info text-center">
                                                    <div className="heading"><img src={require('../../../assets/images/handshake.svg')} />Trusted by Xcarepro</div>
                                                    <h5>{this.state.appointmentDetail && this.state.appointmentDetail.doctor && this.state.appointmentDetail.doctor.fullname ? this.state.appointmentDetail.doctor.fullname : ''}</h5>
                                                    <div><small className="text-muted">{this.state.appointmentDetail && this.state.appointmentDetail.clinic &&
                                                        this.state.appointmentDetail.clinic.name} &nbsp;
                                                        <label>{this.state.appointmentDetail && this.state.appointmentDetail.clinic && this.state.appointmentDetail.clinic.phone ? " - " + this.state.appointmentDetail.clinic.phone : ''}</label></small></div>
                                                </div>
                                            </div>
                                            {
                                                this.state.appointmentDetail && this.state.allowReschedule && this.state.appointmentDetail.date && this.state.appointmentDetail.time && moment().isBefore(moment(this.state.appointmentDetail.date + " " + this.state.appointmentDetail.time, 'YYYY-MM-DD LT')) &&
                                                <div className="info-sec">
                                                    <h6 className="page-title">Reschedule Appointment</h6>
                                                    <div className="d-flex request-listing">
                                                        <div className="media-img me-2 text-center">
                                                            <img src={require('../../../assets/images/ic_notifications_active_24px.svg')} />
                                                        </div>
                                                        <div className="media-body">
                                                            <button onClick={(e) => {
                                                                if (this.state.appointmentDetail && moment().isBefore(moment(this.state.appointmentDetail.date + " " + this.state.appointmentDetail.time, 'YYYY-MM-DD LT'))) {
                                                                    this.reScheduleAppointment(e)
                                                                }
                                                            }}>Reschedule</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div className="info-sec">
                                                <div className="d-flex justify-content-between">
                                                    <h6 className="page-title">Appointment details</h6>

                                                    <div className="profile-info text-center">
                                                        <span className="badge rounded-pill bg-primary text-capitalize">{status}</span>
                                                    </div>
                                                </div>
                                                {
                                                    (this.state.appointmentDetail && this.state.appointmentDetail &&
                                                        this.state.appointmentDetail.appointmentType) &&
                                                    <div className="d-flex request-listing">
                                                        <div className="media-body">
                                                            <div className="desc-content">
                                                                {
                                                                    this.state.appointmentDetail.appointmentType
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    (this.state.appointmentDetail && this.state.appointmentDetail &&
                                                        this.state.appointmentDetail.appointmentType) &&
                                                    <div className="d-flex request-listing">
                                                        <div className="media-body">
                                                            <div className="desc-content">
                                                                {(this.state.appointmentDetail.time && this.state.appointmentDetail.date) &&
                                                                    this.state.appointmentDetail.time + " (" + moment(this.state.appointmentDetail.date).format('MMM DD, YYYY') + ")"
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                <div className="mt-3">
                                                    <h6 className="page-title">Appointment for</h6>
                                                    <div>{pFullname ? pFullname : '-'}</div>
                                                </div>

                                            </div>
                                            <div className="info-sec">
                                                <h6 className="page-title">Provider</h6>
                                                <div className="d-flex request-listing justify-content-between">
                                                    <div className="media-body me-2">
                                                        <div className="desc-content">{this.state.appointmentDetail && this.state.appointmentDetail.doctor ? this.state.appointmentDetail.doctor.fullname : ''}</div>
                                                    </div>
                                                    <div className="media-img text-center">
                                                        {
                                                            (this.state.appointmentDetail && this.state.appointmentDetail.doctor && this.state.appointmentDetail.doctor.fullname && this.state.appointmentDetail.doctor.providerId) &&
                                                            <a target={"_blank"} href={apiConfigs.SOURCE_URL + "dentists/" + location + "/" + this.state.appointmentDetail.doctor.fullname.toLowerCase().trim().replace(/[.,_+]/g, '').replace(/\s+/g, "-") + "/" + this.state.appointmentDetail.doctor.providerId + "/d"}>
                                                                <img src={require('../../../assets/images/ic_visibility_24px.svg')} className="me-1" />
                                                                View Profile
                                                            </a>
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="info-sec">
                                                <h6 className="page-title">Office Address</h6>
                                                <div className="desc-content">{this.state.appointmentDetail && this.state.appointmentDetail.clinic ? this.state.appointmentDetail.clinic.name : ''}</div>
                                                <div className="desc-content">
                                                    {
                                                        (this.state.appointmentDetail && this.state.appointmentDetail.clinic
                                                            && this.state.appointmentDetail.clinic.address) &&
                                                        showAddress(this.state.appointmentDetail.clinic.address)
                                                    }
                                                </div>
                                                <div id="map" className="map-sec" style={{ height: '150px', border: 0 }} onClick={this.handleOpenMap}>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                </Modal>

                            }
                        </div>
                    </div>
                </section>
            </>
        )
    }
    onMessageSendClick(e: any) {
        e.preventDefault();
        if (this.state.appointmentDetail && this.props.patientDataState.patientDataResponse
            && this.state.chatMessageInput !== "" && this.state.doNotShowInput === false) {
            this.setState({ chatMessageInput: '' });
            const obj = {
                "appointmentId": Number(this.state.appointmentDetail.uniqueid),
                "senderId": Number(this.props.patientDataState.patientDataResponse.uniqueId),
                "sender": CHAT.SENDER.PATIENT,
                "senderName": this.props.patientDataState.patientDataResponse.firstName + " " + this.props.patientDataState.patientDataResponse.lastName,
                "action": CHAT.ACTION.SMTC,
                "actionType": CHAT.ACTION_TYPE.MESSAGE,
                "text": this.state.chatMessageInput,
                "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
                "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
                "sendToCId": this.state.appointmentDetail.clinic.uniqueid,
                "sendToDId": this.state.appointmentDetail.doctor.uniqueid,
                "metadata": {
                    "viewByClinicAt": null,
                    "viewByPatientAt": null,
                },
                "dependantId": Number(this.state.appointmentDetail.dependant.uniqueid),
                dateTime: {
                    date: this.state.appointmentDetail.date,
                    time: this.state.appointmentDetail.time
                }
            };
            this.db.collection(apiConfigs.CHAT_COLLECTION_NAME).doc().set(obj)
                .then(() => {
                    console.log("Document successfully written!");
                    this.scrollToBottom();
                })
                .catch((error: any) => {
                    this.props.logout();
                    this.props.history.push('/');
                    console.error("Error writing document: ", error);
                });
        }
    }
    reScheduleAppointment(e: any) {
        e.preventDefault();
        console.log('Called', this.state.appointmentCancelled);
        if (this.state.appointmentDetail && this.props.patientDataState.patientDataResponse && this.state.appointmentCancelled === true) {
            this.setState({ chatMessageInput: '' });
            var objIndex: string = this.state.appointmentDetail.currentStatus.toUpperCase();
            const fromStatus = CHAT.FROM_STATUS[objIndex];
            const obj = {
                "appointmentId": Number(this.state.appointmentDetail.uniqueid),
                "senderId": Number(this.props.patientDataState.patientDataResponse.uniqueId),
                "sender": CHAT.SENDER.PATIENT,
                "action": `Changed status from ${fromStatus} to ${CHAT.TO_STATUS.REQUESTED}.`,
                "actionType": CHAT.ACTION_TYPE.STATUS,
                "fromStatus": fromStatus,
                "toStatus": CHAT.TO_STATUS.REQUESTED,
                "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
                "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
                "dependantId": Number(this.state.appointmentDetail.dependant.uniqueid),
                dateTime: {
                    date: this.state.appointmentDetail.date,
                    time: this.state.appointmentDetail.time
                }
            };
            this.db.collection(apiConfigs.CHAT_COLLECTION_NAME).doc().set(obj)
                .then(() => {
                    console.log("Document successfully written!");
                    // this.appointmentStatuShow(CHAT.TO_STATUS.REQUESTED, fromStatus);
                    this.setState({ allowReschedule: false });
                    this.scrollToBottom();
                })
                .catch((error: any) => {
                    this.props.logout();
                    this.props.history.push('/');
                    console.error("Error writing document: ", error);
                });
        }
    }
    getChatData() {
        // this.setState({ chatArray: [] });
        if (this.props.match && this.props.match.params && this.props.match.params.appointmentId) {
            this.unsubscribe = this.db.collection(apiConfigs.CHAT_COLLECTION_NAME)
                .where("appointmentId", "==", Number(this.props.match.params.appointmentId))
                .orderBy("createdAt")
                .onSnapshot((snapshot: any) => {
                    snapshot.docChanges().forEach((change: any) => {
                        if (change.type === "added") {
                            if (change.doc.data().allowRescheduling === true &&
                                change.doc.data().toStatus === CHAT.TO_STATUS.CANCELLED) {
                                this.setState({ allowReschedule: true });
                            } else {
                                this.setState({ allowReschedule: false });
                            }
                            this.setState({
                                chatArray: [...this.state.chatArray, {
                                    ...change.doc.data(),
                                    id: change.doc.id
                                }
                                ]
                            });
                            this.appointmentStatuShow(change.doc.data().toStatus, change.doc.data().fromStatus);
                            /* if (change.doc.data().toStatus === CHAT.TO_STATUS.CANCELLED) {
                                this.setState({ appointmentCancelled: true });
                            } */
                        }
                        if (change.type === "modified") {
                            const id = change.doc.id;
                            this.setState({
                                chatArray: this.state.chatArray.map((value, index) => {
                                    if (value.id === id) {
                                        return { ...change.doc.data(), id: id };
                                    }
                                    return value;
                                })
                            });
                            this.appointmentStatuShow(change.doc.data().toStatus, change.doc.data().fromStatus);
                            /* if (change.doc.data().toStatus === CHAT.TO_STATUS.CANCELLED) {
                                this.setState({ appointmentCancelled: true });
                            } */
                            console.log("Modified : ", change.doc.data());
                        }
                        if (change.type === "removed") {
                            console.log("Removed : ", change.doc.data());
                        }
                        this.scrollToBottom();
                    });
                }, (error: any) => {
                    this.props.logout();
                    this.props.history.push('/');
                    console.log(error);
                });
        }
    }
    scrollToBottom = () => {
        // this.messagesEnd.scrollIntoView({ behavior: "smooth" });
        /* var element: any = document.getElementById("chatBox");
        element.scrollTop = element.scrollHeight; */

        $("#chatBox").animate({ scrollTop: $('#chatBox').prop("scrollHeight") }, 50);
    }
    appointmentStatuShow(status: any, fromStatus: any = "") {
        if (status === CHAT.TO_STATUS.CANCELLED && this.state.appointmentDetail) {
            this.setState({
                appointmentCancelled: true,
                doNotShowInput: false,
                appointmentCompleted: false,
                appointmentDetail: {
                    ...this.state.appointmentDetail,
                    currentStatus: APPOINTMENT_STATUS.CANCEL[1]
                }
            });
        } else if (status === CHAT.TO_STATUS.SCHEDULED && this.state.appointmentDetail) {
            this.setState({
                appointmentCancelled: true,
                appointmentCompleted: false,
                doNotShowInput: false,
                appointmentDetail: {
                    ...this.state.appointmentDetail,
                    currentStatus: APPOINTMENT_STATUS.SCHEDULE[1]
                }
            });
        } else if (status === CHAT.TO_STATUS.COMPLETED && this.state.appointmentDetail) {
            this.setState({
                doNotShowInput: false,
                appointmentCancelled: true,
                appointmentCompleted: true,
                appointmentDetail: {
                    ...this.state.appointmentDetail,
                    currentStatus: APPOINTMENT_STATUS.COMPLETE[1]
                }
            });
        } else if (status === CHAT.TO_STATUS.REQUESTED && this.state.appointmentDetail) {
            this.setState({
                doNotShowInput: false,
                appointmentCancelled: false,
                appointmentCompleted: false,
                appointmentDetail: {
                    ...this.state.appointmentDetail,
                    currentStatus: APPOINTMENT_STATUS.REQUEST[1]
                }
            });
            if (fromStatus === CHAT.FROM_STATUS.CANCELLED) {
            }
        }
    }
    componentWillUnmount() {
        const login: any = getCookie();
        if (login && login.xpr_user_id) {
            this.props.patientData({ uniqueId: login.xpr_user_id });
        } else {
            window.location.href = "/";
        }
        this.unsubscribe && this.unsubscribe();
    }
}
const mapDispatchToProps = (dispatch: any) => ({
    appointmentDetail: (request: AppointmentDetailRequest) => {
        dispatch(appointmentDetailPending(request));
    },
    changeAppointmentReminder: (request: ChangeAppointmentRemiderRequest) => {
        dispatch(changeAppointmentRemiderPending(request));
    },
    patientData: (request: PatientDataRequest) => {
        dispatch(patientDataPending(request));
    },
    logout: () => {
        dispatch(userLogout())
    },
});
const mapStateToProps = (state: IAppState
) => ({
    appointmentDetailState: state.appointmentDetail,
    patientDataState: state.patientData
});
export default connect(mapStateToProps, mapDispatchToProps)(AppointmentDetail);